import StaticChart from "../../../components/chart/line/static/StaticChart"
import Navbar from "../../../components/navbar/Navbar"
import Sidebar from "../../../components/sidebar/Sidebar"
import SimpleTable from "../../../components/simpletable/SimpleTable"
import { useEffect, useState } from "react"
import moment from "moment"
import Loading from "../../../components/loading/Loading"
import useAxios from "../../../hooks/useAxios"
import Select, { CSSObjectWithLabel } from "react-select"
import {
	selectCurrentMenuState,
	selectCurrentTheme,
} from "../../../redux/menuSlice"
import { useSelector } from "react-redux"

const getLast6Months = () => {
	let arr = []
	for (let n = 0; n < 6; n++)
		arr.push(moment().subtract(n, "months").format("MMMM YYYY"))
	return arr
}

const StaticSMS = () => {
	const showMenu = useSelector(selectCurrentMenuState)
	const [provider, setProvider] = useState<string[]>(["Twilio"])
	const [response, setResponse] = useState<any>({})
	const [mtdOption, setMTDOption] = useState("SMS Service Providers")
	const [type, setType] = useState<string[]>([])
	const dateRange = getLast6Months()
	const [date, setDate] = useState(dateRange[0])
	const [monthlySpent, setMonthlySpent] = useState<[]>([])
	const [mtd, setMTD] = useState<any[]>([])
	const [isMTDLoading, setIsMTDLoading] = useState(true)
	const [isMonthlyLoading, setIsMonthlyLoading] = useState(true)
	const fetch = useAxios()

	useEffect(() => {
		const fetchMonthlySpent = async () => {
			let resp = await fetch.get("/report/monthlyspent")

			setMonthlySpent(resp.data)
			setIsMonthlyLoading(false)
		}

		fetchMonthlySpent()
	}, [])

	useEffect(() => {
		const fetchMTD = async () => {
			setMTD([])
			setIsMTDLoading(true)
			let resp = await fetch.post("/report/mtd", {
				month: Number(moment(date).format("M")),
				year: Number(moment(date).format("YYYY")),
			})

			setResponse(resp.data)
			setType(Object.keys(resp.data["SMS Service Providers"]))
			setIsMTDLoading(false)
		}

		fetchMTD()
	}, [date])

	useEffect(() => {
		if (Object.keys(response).length !== 0) {
			let defaultProvider = Object.keys(response[mtdOption])[0]
			setProvider([defaultProvider])
			setType(Object.keys(response[mtdOption]))
			setMTDOption(mtdOption)
			setMTD(response[mtdOption][defaultProvider])
		}
	}, [mtdOption, response])

	/**
	 * When there is a change in provider field,
	 * we concatenate the data from each day from all providers into one object
	 * such that it is of the form:
	 * {
	 * 		name: ${date},
	 * 		provider1: value,
	 * 		provider2: value
	 * }
	 *
	 * This is done by taking the first provider,
	 * and for each remaining provider, add to the current object
	 */
	useEffect(() => {
		if (Object.keys(response).length !== 0) {
			if (provider.length === 0) setMTD([])
			else {
				let data = response[mtdOption][provider[0]].map(
					(day: any, idx: number) => {
						let temp = structuredClone(day)
						provider.slice(1).forEach((prov) => {
							let balanceKeys = Object.keys(
								response[mtdOption][prov][1]
							).filter((el) => el !== "name")

							for (const balanceKey of balanceKeys)
								temp[balanceKey] = response[mtdOption][prov][idx][balanceKey]
						})
						return temp
					}
				)
				setMTD(data)
			}
			setProvider(provider)
		}
	}, [provider, response])

	return isMonthlyLoading || isMTDLoading ? (
		<Loading />
	) : (
		<div className="flex h-[1px] min-h-screen w-full flex-col ">
			<Navbar />
			<div className="flex max-h-screen min-w-[700px] max-w-[99.5%] flex-row">
				<Sidebar />
				<div
					className={`mt-14 flex h-live-container w-full flex-col p-5 ${
						!showMenu ? "ml-28 w-container" : ""
					}`}
				>
					<h2 className="p-3 text-4xl font-bold text-tvsd-blue dark:text-tvsd-red">
						Monthly SMS Expenditures
					</h2>
					<div className="p-3">
						<SimpleTable tableContent={monthlySpent} />
					</div>
					<div className="p-3">
						<div className="flex w-full flex-col lg:flex-row">
							<div className="flex w-full flex-col border-b-2 border-r-2 border-gray-300 p-3 dark:border-neutral-800 lg:w-80">
								Metric
								<div className="flex h-full w-full items-center">
									<Select
										className="select-container"
										classNamePrefix="select"
										options={Object.keys(response).map((type) => ({
											value: type,
											label: type,
										}))}
										value={{ value: mtdOption, label: mtdOption }}
										onChange={(type) => (type ? setMTDOption(type.value) : "")}
									/>
								</div>
							</div>
							<div className="flex w-full flex-col border-b-2 border-r-2 border-gray-300 p-3 dark:border-neutral-800 lg:w-80">
								Month
								<div className="flex h-full w-full items-center">
									<Select
										className="select-container"
										classNamePrefix="select"
										options={dateRange.map((dates) => ({
											value: dates,
											label: dates,
										}))}
										value={{ value: date, label: date }}
										onChange={(type) => (type ? setDate(type.value) : "")}
									/>
								</div>
							</div>
							<div className="w-full border-b-2 border-r-2 border-gray-300 p-3 dark:border-neutral-800 lg:w-[calc(100vw-40rem)]">
								Options
								<Select
									className="select-container"
									classNamePrefix="select"
									isMulti={true}
									options={type.map((type) => ({
										value: type,
										label: type,
									}))}
									value={provider.map((el) => ({ value: el, label: el }))}
									onChange={(type) =>
										type ? setProvider(type.map((el) => el.value)) : ""
									}
								/>
							</div>
						</div>
						<StaticChart data={mtd} />
					</div>
				</div>
			</div>
		</div>
	)
}

export default StaticSMS
