import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"

const SimpleTable = ({ tableContent }: { tableContent: any }) => {
	return (
		<TableContainer
			component={Paper}
			className="dark:bg-neutral-800 dark:!shadow-md dark:!shadow-black"
		>
			<Table sx={{ minWidth: 650 }} aria-label="simple table">
				<TableHead className="!border-neutral-700 bg-gray-300 dark:bg-neutral-900">
					<TableRow>
						{tableContent.length !== 0
							? tableContent.Headers.map((el: []) => (
									<TableCell className="!border-neutral-700 !text-base !font-bold dark:!text-white">
										{el}
									</TableCell>
							  ))
							: ""}
					</TableRow>
				</TableHead>
				<TableBody>
					{tableContent.length !== 0
						? Object.entries(tableContent.Rows).map(
								([provider, spendings]: any[], i) => (
									<TableRow
										sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
									>
										{spendings.map((spending: number) => (
											<TableCell className="!border-neutral-700 dark:!text-white">
												{spending}
											</TableCell>
										))}
									</TableRow>
								)
						  )
						: ""}
				</TableBody>
			</Table>
		</TableContainer>
	)
}

export default SimpleTable
