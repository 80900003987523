import { useEffect } from "react"
import { useSelector } from "react-redux"
import { selectCurrentUser } from "../../redux/authSlice"
import OtpInput from "react-otp-input"
import {
	useResendOTPMutation,
	useVerifyOTPMutation,
} from "../../redux/authApiSlice"
import { useNavigate } from "react-router-dom"
import TVSDLOGO from "../../assets/TVSDLOGO.svg"
import { Form, Formik } from "formik"
import CircularProgress from "@mui/material/CircularProgress/CircularProgress"
import * as Yup from "yup"
import { useAlert } from "react-alert"
import { selectCurrentTheme } from "../../redux/menuSlice"

const VerifyOTP = () => {
	const theme = useSelector(selectCurrentTheme)
	const { email } = useSelector(selectCurrentUser)
	const [verifyOTP] = useVerifyOTPMutation()
	const [resendOTP] = useResendOTPMutation()
	const navigate = useNavigate()
	const alert = useAlert()

	useEffect(() => {
		//if (!email) navigate("/login")
	}, [])

	const content = (
		<div className="flex min-h-screen flex-col justify-center">
			<div className="mx-auto w-full max-w-xl ">
				<div className="mb-3 flex h-28 items-center justify-center">
					<img src={TVSDLOGO} alt="tvsdlogo" className="h-14" />
				</div>

				<div className="h-auto rounded-3xl border bg-white shadow-lg dark:border-neutral-700 dark:bg-neutral-800 dark:shadow-black placeholder:dark:text-white sm:h-96">
					<Formik
						initialValues={{ otp: "" }}
						onSubmit={async (values, actions) => {
							let resp = await verifyOTP({ email: email, otp: values.otp })
							if (resp?.error)
								actions.setStatus({
									error: resp.error.data.message,
								})
							else if (resp?.data?.message === "Account verified") {
								navigate("/login")
								alert.show(resp.data.message, {
									type: "success",
								})
							} else
								actions.setStatus({
									error: resp.error.data.message,
								})

							actions.setSubmitting(false)
						}}
						validateOnChange={false}
						validateOnBlur={false}
						validationSchema={Yup.object().shape({
							otp: Yup.string().length(6).required(),
						})}
					>
						{(props) => {
							const {
								values,
								errors,
								setFieldValue,
								isSubmitting,
								status,
								setSubmitting,
							} = props
							return (
								<Form className="flex flex-col p-10">
									<h1 className="mb-4 text-3xl">Account Confirmation</h1>
									<p className="text-mg mb-5">
										{`Enter the One Time Password (OTP) sent to ${email} to verify your account`}
									</p>
									<div className="mx-auto mb-5 mt-4 flex h-16 w-full flex-col items-center justify-center">
										<OtpInput
											value={values.otp}
											onChange={(e: string) => setFieldValue("otp", e)}
											numInputs={6}
											separator={<span></span>}
											inputStyle={{
												width: "80%",
												height: "100%",
												"border-bottom-width": "2px",
												"border-color": "gray",
												"margin-left": "auto",
												"margin-right": "auto",
												backgroundColor: "inherit",
											}}
											containerStyle="w-full h-full justify-between"
										/>
										{errors.otp ? (
											<div className="mt-1 text-red-600">{errors.otp}</div>
										) : null}
									</div>
									<div className="flex items-center justify-evenly pt-10">
										{!isSubmitting ? (
											<>
												<button
													className="h-10 w-2/5 rounded-lg bg-red-600 text-white"
													disabled={isSubmitting}
													onClick={async () => {
														setSubmitting(true)
														let resp = await resendOTP({ email: email })
														if (resp?.error?.status)
															alert.show(resp?.error?.data?.message, {
																type: "error",
															})
														else if (resp?.data?.message) {
															alert.show(resp.data.message, {
																type: "success",
															})
														} else
															alert.show("Error has occurred!", {
																type: "error",
															})
														setSubmitting(false)
													}}
												>
													Resend OTP
												</button>
												<button
													type="submit"
													className="h-10 w-2/5 rounded-lg bg-tvsd-blue text-white"
													disabled={isSubmitting}
												>
													Verify OTP
												</button>
											</>
										) : (
											<CircularProgress />
										)}
									</div>
									{status ? (
										<div className="mt-3 text-center text-red-600">
											{status.error}
										</div>
									) : null}
								</Form>
							)
						}}
					</Formik>
				</div>
				<div className="mt-6 h-14 text-center ">
					<p>
						Already have an account?{" "}
						<a
							onClick={() => navigate("/login")}
							className="cursor-pointer text-blue-700"
						>
							Sign In
						</a>
					</p>
				</div>
			</div>
		</div>
	)
	return content
}

export default VerifyOTP
