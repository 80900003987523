import { useDispatch } from "react-redux"
import { authenticate } from "../../redux/authSlice"
import { useNavigate } from "react-router-dom"
import { useLoginMutation } from "../../redux/authApiSlice"
import { CircularProgress } from "@mui/material"
import { Form, Formik } from "formik"
import * as Yup from "yup"
import TVSDLOGO from "../../assets/TVSDLOGO.svg"

const Login = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const [login] = useLoginMutation()

	const content = (
		<div className="flex min-h-screen flex-col justify-center">
			<div className="mx-auto w-full max-w-xl">
				<div className="mb-3 flex h-28 items-center justify-center">
					<img src={TVSDLOGO} alt="tvsdlogo" className="h-14" />
				</div>

				<div className="h-auto rounded-3xl border bg-slate-100 shadow-lg dark:border-neutral-700 dark:bg-neutral-900 dark:shadow-black sm:h-96">
					<Formik
						initialValues={{ email: "", password: "" }}
						onSubmit={async (values, actions) => {
							const resp = await login({
								email: values.email,
								password: values.password,
							})
							if (
								resp?.error?.status === 401 ||
								resp?.error?.status === 400 ||
								resp?.error?.status === 503
							)
								actions.setStatus({
									error: resp.error.data.message,
								})
							else {
								dispatch(authenticate({ ...resp.data }))
								navigate("/home")
							}
							actions.setSubmitting(false)
						}}
						validateOnChange={false}
						validationSchema={Yup.object().shape({
							email: Yup.string()
								.email("Invalid email")
								.matches(
									/^[A-Za-z0-9._%+-]+@(tvsd\.ai|tvsmotor.com)$/,
									"Invalid email domain"
								)
								.required("Email is a required field"),
							password: Yup.string().required("Password is a required field"),
						})}
					>
						{(props) => {
							const { errors, handleChange, isSubmitting, status } = props
							return (
								<Form className="flex flex-col p-10">
									<h1 className="mb-4 text-3xl">Sign In</h1>
									<p className="text-mg mb-5">
										Enter your Reporting Engine account details
									</p>
									<div className="mb-5">
										<input
											type="text"
											placeholder="Email"
											name="email"
											onChange={handleChange}
											className="h-12 w-full rounded-md border border-gray-400 bg-[length:30px] bg-no-repeat pl-4 focus:outline-blue-900 dark:border-neutral-700 dark:bg-neutral-800 placeholder:dark:text-white sm:bg-mail sm:pl-14"
											style={{ backgroundPosition: "1rem center" }}
										/>
										{errors.email ? (
											<div className="mt-1 text-red-600">{errors.email}</div>
										) : null}
									</div>
									<div className="mb-7">
										<input
											type="password"
											placeholder="Password"
											name="password"
											onChange={handleChange}
											className="h-12 w-full rounded-md border border-gray-400 bg-[length:30px] bg-no-repeat pl-4 focus:outline-blue-900 dark:border-neutral-700 dark:bg-neutral-800 placeholder:dark:text-white sm:bg-key sm:pl-14"
											style={{ backgroundPosition: "1rem center" }}
										/>
										{errors.password ? (
											<div className="mt-1 text-red-600">{errors.password}</div>
										) : null}
									</div>
									<div className="flex items-center justify-center">
										{!isSubmitting ? (
											<button
												type="submit"
												className="h-10 w-1/2 rounded-lg bg-tvsd-blue text-white"
												disabled={isSubmitting}
											>
												Sign In
											</button>
										) : (
											<CircularProgress />
										)}
									</div>
									{status ? (
										<div className="mt-3 text-center text-red-600">
											{status.error}
										</div>
									) : null}
								</Form>
							)
						}}
					</Formik>
				</div>
				<div className="mt-6 h-14 text-center ">
					<p>
						Don't have an account?{" "}
						<a
							onClick={() => navigate("/register")}
							className="cursor-pointer text-blue-700"
						>
							Sign Up
						</a>
					</p>
					<a
						onClick={() => navigate("/forgotpassword")}
						className="cursor-pointer text-blue-700"
					>
						Forgot Password?
					</a>
				</div>
			</div>
		</div>
	)
	return content
}

export default Login
