import DynamicChart from "../../chart/line/dynamic/DynamicChart"
import { useContext, useEffect, useState } from "react"
import { GraphContext, GraphContextI } from "../../../context/GraphContext"

const SMSBalance = ({
	type,
	context,
	fetchData,
}: {
	type: string
	context: any
	fetchData: () => Promise<void>
}) => {
	const {
		refresh,
		pause,
		counter,
		pauseData,
		setPauseData,
		setDisplayData,
		setSliderValue,
		setLastUpdated,
		setChange,
	} = useContext<GraphContextI>(context)

	const [firstRender, setFirstRender] = useState(true)

	useEffect(() => {
		if (!firstRender) {
			if (!pause) {
				// update chart to display stored data
				setDisplayData(pauseData)
				// update slider back to max
				setSliderValue(100)
			}
			window.sessionStorage.setItem(type, JSON.stringify(pauseData))
		}
	}, [pauseData, pause])

	useEffect(() => {
		// ran on first initial render to retrieve cached data from session storage,
		// then immediately fetches a new set of data
		let sessionData = window.sessionStorage.getItem(type)
		if (sessionData) setPauseData(JSON.parse(sessionData))
		if (sessionData) setDisplayData(JSON.parse(sessionData))
		if (sessionData && JSON.parse(sessionData).at(-1))
			setLastUpdated(JSON.parse(sessionData).at(-1).name)
		let changeData = window.sessionStorage.getItem(type + "Change")
		if (changeData) setChange(JSON.parse(changeData).at(-1).change)
		else setChange(0)
		setFirstRender(false)
		fetchData()
	}, [])

	useEffect(() => {
		const intervalId = setInterval(() => {
			fetchData()
		}, 60 * 60 * 1000)

		return () => {
			clearInterval(intervalId)
		}
	}, [counter])

	useEffect(() => {
		if (!firstRender) fetchData()
	}, [refresh])

	return (
		<div className="h-full w-full rounded-lg border border-gray-300 shadow-md dark:border-neutral-800 dark:shadow-md dark:shadow-black sm:w-4/5">
			<DynamicChart
				context={GraphContext}
				title={type + " Balance (Fetched every hour)"}
			/>
		</div>
	)
}

export default SMSBalance
