import * as React from "react"
import { alpha } from "@mui/material/styles"
import Box from "@mui/material/Box"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableSortLabel from "@mui/material/TableSortLabel"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import Paper from "@mui/material/Paper"
import Checkbox from "@mui/material/Checkbox"
import IconButton from "@mui/material/IconButton"
import Tooltip from "@mui/material/Tooltip"
import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import AddIcon from "@mui/icons-material/Add"
import DoneIcon from "@mui/icons-material/Done"
import CloseIcon from "@mui/icons-material/Close"
import { useContext } from "react"
import {
	AlertContextI,
	AlertCrudEnum,
	DefaultAlertContextValue,
} from "../../context/AlertContext"

interface EnhancedTableProps {
	numSelected: number
	onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
	rowCount: number
}

interface EnhancedTableToolbarProps {
	numSelected: number
}

export default function CrudTable({
	headers,
	data,
	order,
	title,
	context,
}: {
	headers: any
	data: any
	order: any
	title: string
	context: any
}) {
	const [selected, setSelected] = React.useState<readonly number[]>([])
	const headCells = headers
	const rows = data
	const { setOpenModal, alertData, setAlertCrud, setSelectedRow } =
		useContext<AlertContextI>(context)

	const EnhancedTableHead = (props: EnhancedTableProps) => {
		const { onSelectAllClick, numSelected, rowCount } = props
		return (
			<TableHead className="!border-neutral-700 dark:bg-neutral-900">
				<TableRow>
					<TableCell
						padding="checkbox"
						className="!border-neutral-700 !text-base !font-bold dark:!text-white"
					>
						{rowCount !== 0 ? (
							<Checkbox
								color="primary"
								indeterminate={numSelected > 0 && numSelected < rowCount}
								checked={rowCount > 0 && numSelected === rowCount}
								onChange={onSelectAllClick}
								inputProps={{
									"aria-label": "select all alerts",
								}}
							/>
						) : (
							""
						)}
					</TableCell>
					{headCells.map((headCell: any) =>
						headCell.id !== "ID" ? (
							<TableCell
								key={headCell.id}
								align={headCell.alignRight ? "right" : "left"}
								padding={headCell.disablePadding ? "none" : "normal"}
								sortDirection={false}
								className="!border-neutral-700 !font-bold dark:!text-white"
							>
								<TableSortLabel hideSortIcon={true}>
									{headCell.label}
								</TableSortLabel>
							</TableCell>
						) : null
					)}
				</TableRow>
			</TableHead>
		)
	}

	const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
		const { numSelected } = props

		return (
			<Toolbar
				sx={{
					pl: { sm: 2 },
					pr: { xs: 1, sm: 1 },
					...(numSelected > 0 && {
						bgcolor: (theme) =>
							alpha(
								theme.palette.primary.main,
								theme.palette.action.activatedOpacity
							),
					}),
				}}
				className="border-b dark:border-neutral-700 dark:bg-neutral-800 dark:text-white"
			>
				{numSelected > 0 ? (
					<Typography
						sx={{ flex: "1 1 100%" }}
						color="inherit"
						variant="subtitle1"
						component="div"
					>
						{numSelected} selected
					</Typography>
				) : (
					<Typography
						sx={{ flex: "1 1 100%" }}
						variant="h6"
						id="tableTitle"
						component="div"
					>
						{title}
					</Typography>
				)}
				{numSelected === 0 ? (
					<Tooltip title="Create new">
						<IconButton
							onClick={() => {
								setAlertCrud(AlertCrudEnum.Create)
								setOpenModal(true)
							}}
						>
							<AddIcon />
						</IconButton>
					</Tooltip>
				) : numSelected === 1 ? (
					<>
						<Tooltip title="Edit">
							<IconButton
								onClick={() => {
									setAlertCrud(AlertCrudEnum.Edit)
									setOpenModal(true)
								}}
							>
								<EditIcon />
							</IconButton>
						</Tooltip>
						<Tooltip title="Delete">
							<IconButton onClick={() => setAlertCrud(AlertCrudEnum.Delete)}>
								<DeleteIcon />
							</IconButton>
						</Tooltip>
					</>
				) : (
					<Tooltip title="Delete">
						<IconButton onClick={() => setAlertCrud(AlertCrudEnum.Delete)}>
							<DeleteIcon />
						</IconButton>
					</Tooltip>
				)}
			</Toolbar>
		)
	}

	const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			const newSelected = rows.map((n: any) => n[order[0]])
			let selectedRow: any[] = newSelected.map((rowId: any) =>
				alertData.find((alert) => alert.ID === rowId)
			)
			setSelectedRow(selectedRow)
			setSelected(newSelected)
			return
		}
		setSelected([])
	}

	const handleClick = (event: React.MouseEvent<unknown>, name: number) => {
		const selectedIndex = selected.indexOf(name)
		let newSelected: readonly number[] = []

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name)
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1))
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1))
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			)
		}

		let selectedRow: any[]
		if (newSelected.length === 0)
			selectedRow = DefaultAlertContextValue.selectedRow
		else
			selectedRow = newSelected.map((rowId) =>
				alertData.find((alert) => alert.ID === String(rowId))
			)

		setSelectedRow(selectedRow)
		setSelected(newSelected)
	}

	const isSelected = (name: number) => selected.indexOf(name) !== -1

	return (
		<Box sx={{ width: "100%" }}>
			<Paper sx={{ width: "100%", mb: 2 }}>
				<EnhancedTableToolbar numSelected={selected.length} />
				<TableContainer className="dark:bg-neutral-800 dark:!shadow-md dark:!shadow-black">
					<Table
						sx={{ minWidth: 750 }}
						aria-labelledby="tableTitle"
						size="medium"
					>
						<EnhancedTableHead
							numSelected={selected.length}
							onSelectAllClick={handleSelectAllClick}
							rowCount={rows.length}
						/>
						<TableBody>
							{rows.slice().map((row: any, index: number) => {
								const isItemSelected = isSelected(row[order[0]])
								const labelId = `enhanced-table-checkbox-${index}`
								return (
									<TableRow
										hover
										onClick={(event) => handleClick(event, row[order[0]])}
										role="checkbox"
										aria-checked={isItemSelected}
										tabIndex={-1}
										key={row[order[0]]}
										selected={isItemSelected}
										className="!border-neutral-700 dark:bg-neutral-900"
									>
										<TableCell
											padding="checkbox"
											className="!border-neutral-700 dark:!text-white"
										>
											<Checkbox
												color="primary"
												checked={isItemSelected}
												inputProps={{
													"aria-labelledby": labelId,
												}}
											/>
										</TableCell>
										{order.map((col: any, idx: number) => {
											if (idx === 0) return
											else
												return (
													<TableCell
														align={headCells[idx].alignRight ? "right" : "left"}
														key={idx}
														className="!border-neutral-700 dark:!text-white"
													>
														{row[col] === true ? (
															<DoneIcon className="!text-green-500" />
														) : row[col] === false ? (
															<CloseIcon className="!text-red-500" />
														) : (
															row[col]
														)}
													</TableCell>
												)
										})}
									</TableRow>
								)
							})}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
		</Box>
	)
}
