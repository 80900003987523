import TimelineIcon from "@mui/icons-material/Timeline"
import InsertChartIcon from "@mui/icons-material/InsertChart"
import SmsIcon from "@mui/icons-material/Sms"
import ExitToAppIcon from "@mui/icons-material/ExitToApp"
import AddAlertIcon from "@mui/icons-material/AddAlert"
import { useDispatch, useSelector } from "react-redux"
import { logout } from "../../redux/authSlice"
import { useNavigate, Link } from "react-router-dom"
import { useLogoutMutation } from "../../redux/authApiSlice"
import { useEffect, useState } from "react"
import SummarizeIcon from "@mui/icons-material/Summarize"
import { selectCurrentMenuState } from "../../redux/menuSlice"
import Modal from "react-responsive-modal"
import IconButton from "@mui/material/IconButton/IconButton"

const Sidebar = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const [apiLogout] = useLogoutMutation()
	const showMenu = useSelector(selectCurrentMenuState)
	const [showLogoutMenu, setShowLogoutMenu] = useState(false)

	const handleLogout = async () => {
		try {
			await apiLogout()
			dispatch(logout())
			navigate("/login")
		} catch (err) {
			console.error(err)
		}
	}

	return (
		<div
			className={`fixed z-0 mt-14 flex h-container border border-r-2 dark:border-neutral-800 ${
				showMenu ? "w-0" : "w-28"
			} `}
		>
			<div
				className={`flex ${
					showMenu
						? "hidden"
						: "flex flex-col items-center justify-between p-2 text-sm"
				}`}
			>
				<ul>
					<div className={`${showMenu ? "border-b-2 pl-9" : null}`}>
						<p className={`${showMenu ? "font-bold" : "hidden"}`}>
							LIVE UPDATE
						</p>
						<li className={`${showMenu ? "my-4" : "my-5"}`}>
							<Link
								to="/live"
								style={{ textDecoration: "none" }}
								className={
									showMenu ? "" : `flex flex-col items-center justify-center`
								}
							>
								<TimelineIcon
									className={`scale-150 ${showMenu ? "mr-5" : ""}`}
								/>
								<span className="lines mt-3 text-center leading-4">
									Live SMS Balance
								</span>
							</Link>
						</li>
					</div>
					<div className={`${showMenu ? "border-b-2 pl-9" : null}`}>
						<p className={`${showMenu ? "mt-4 font-bold" : "hidden"}`}>
							STATIC UPDATE
						</p>
						<li className="flex flex-col">
							<Link
								to="/static"
								style={{ textDecoration: "none" }}
								className={
									showMenu
										? "mt-4"
										: `mb-5 flex flex-col items-center justify-center`
								}
							>
								<SmsIcon className={`scale-150 ${showMenu ? "mr-5" : ""}`} />
								<span className="lines mt-3 text-center leading-4">
									SMS Expenditures
								</span>
							</Link>
							<Link
								to="/dailyinfo"
								style={{ textDecoration: "none" }}
								className={
									showMenu
										? "my-4"
										: `mb-5 flex flex-col items-center justify-center`
								}
							>
								<InsertChartIcon
									className={`scale-150 ${showMenu ? "mr-5" : ""}`}
								/>
								<span className="lines mt-3 text-center leading-4">
									Daily Information
								</span>
							</Link>
						</li>
					</div>
					<div className={`${showMenu ? "border-b-2 pl-9" : null}`}>
						<p className={`${showMenu ? "mt-4 font-bold" : "hidden"}`}>
							ALERT SYSTEM
						</p>
						<li className={showMenu ? "my-4" : ""}>
							<Link
								to="/alert"
								style={{ textDecoration: "none" }}
								className={
									showMenu
										? ""
										: `mb-5 flex flex-col items-center justify-center`
								}
							>
								<AddAlertIcon
									className={`scale-150 ${showMenu ? "mr-5" : ""}`}
								/>
								<span className="lines mt-3 text-center leading-4">Alerts</span>
							</Link>
						</li>
					</div>
					<div className={`${showMenu ? "border-b-2 pl-9" : null}`}>
						<p className={`${showMenu ? "mt-4 font-bold" : "hidden"}`}>
							PDF REPORT
						</p>
						<li className="my-5">
							<Link
								to="/report"
								style={{ textDecoration: "none" }}
								className={
									showMenu ? "" : `flex flex-col items-center justify-center`
								}
							>
								<SummarizeIcon
									className={`scale-150 ${showMenu ? "mr-5" : ""}`}
								/>
								<span className="lines mt-3 text-center leading-4">
									Operation Report
								</span>
							</Link>
						</li>
					</div>
				</ul>
				<div
					className={
						showMenu ? "" : `flex flex-col items-center justify-center`
					}
				>
					<form
						onClick={() => setShowLogoutMenu(true)}
						className={
							showMenu ? "" : `flex flex-col items-center justify-center`
						}
					>
						<button
							type="button"
							className={
								showMenu
									? "my-3 pl-9"
									: `flex flex-col items-center justify-center`
							}
						>
							<ExitToAppIcon
								className={`scale-150 ${showMenu ? "mr-5" : ""}`}
							/>
							<span className="mt-2 text-center">Logout</span>
						</button>
					</form>
					{showLogoutMenu ? (
						<Modal
							open={showLogoutMenu}
							onClose={() => setShowLogoutMenu(false)}
							center
							styles={{
								modal: {
									maxWidth: "400px",
									maxHeight: "260px",
									borderRadius: "0.5rem",
								},
							}}
							classNames={{
								modal:
									"max-w-[400px] max-h-[260px] rounded-sm dark:bg-neutral-800",
							}}
						>
							<h2 className="pt-2 text-center text-2xl font-bold">Logout</h2>
							<p className="py-2 text-center text-base font-medium">
								You will be returned to the login screen.
							</p>
							<div className="inline-flex w-full p-2">
								<button
									onClick={() => setShowLogoutMenu(false)}
									className="mr-5 h-10 w-1/2 rounded-lg  bg-tvsd-blue text-white"
								>
									Cancel
								</button>
								<button
									onClick={handleLogout}
									className="h-10 w-1/2 rounded-lg bg-tvsd-red text-white"
								>
									Log out
								</button>
							</div>
						</Modal>
					) : null}
				</div>
			</div>
		</div>
	)
}

export default Sidebar
