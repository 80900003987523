import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import {
	seenNotification,
	selectCurrentUser,
	selectNotificationCount,
} from "../../redux/authSlice"
import MenuIcon from "@mui/icons-material/Menu"
import IconButton from "@mui/material/IconButton"
import DarkModeIcon from "@mui/icons-material/DarkMode"
import LightModeIcon from "@mui/icons-material/LightMode"
import {
	selectCurrentTheme,
	toggleMenu,
	toggleTheme,
} from "../../redux/menuSlice"
import {
	useDeleteNotificationMutation,
	useNotificationsMutation,
} from "../../redux/authApiSlice"
import Notifications from "react-notifications-menu"
import moment from "moment"
import aakash from "../../assets/aakash.png"
import mim from "../../assets/mim.png"
import semaphore from "../../assets/semaphore.png"
import twilio from "../../assets/twilio.png"
import { useAlert } from "react-alert"
import TVSDLOGO from "../../assets/TVSDLOGO.svg"
import NotificationIconDark from "../../assets/notifications_icon_dark.png"
import NotificationIconLight from "../../assets/notificaitons_icon_light.png"
import { useNavigate } from "react-router-dom"
import { Tooltip } from "@mui/material"

interface NotificationI {
	message: string
	date: string
}

const convertToImage = (imageName: string) => {
	switch (imageName) {
		case "Twilio":
			return twilio
		case "Semaphore":
			return semaphore
		case "MiM SMS":
			return mim
		case "Aakash SMS":
			return aakash
	}
}

const Navbar = () => {
	const theme = useSelector(selectCurrentTheme)
	const [notifications, setNotifications] = useState<NotificationI[]>()
	const [show, setShow] = useState(false)
	const [getNotification] = useNotificationsMutation()
	const [deleteNotification] = useDeleteNotificationMutation()
	const alert = useAlert()
	const dispatch = useDispatch()
	const user = useSelector(selectCurrentUser)
	const notificationCounts = useSelector(selectNotificationCount)
	const navigate = useNavigate()

	const handleMenuToggle = () => {
		dispatch(toggleMenu())
	}

	useEffect(() => {}, [show])

	const handleClear = async () => {
		const resp = await deleteNotification({ email: user.email })
		if (resp?.error)
			alert.show(resp.error?.data?.message, {
				type: "error",
			})
		else
			alert.show(resp?.data?.message, {
				type: "success",
			})
		setNotifications(undefined)
	}

	const handleNotificationClick = async () => {
		if (!show) {
			const resp = await getNotification({ email: user.email })
			if (resp?.error) {
				alert.show(resp.error?.data?.message, {
					type: "error",
				})
				return
			}
			dispatch(seenNotification())
			setNotifications(
				resp.data.map((notification: any) => {
					let temp = structuredClone(notification)
					const receivedAgo = moment.duration(
						moment().diff(moment(temp.receivedTime))
					)
					if (receivedAgo.asHours() < 1)
						temp.receivedTime = "Less than 1 hour ago"
					else if (receivedAgo.asHours() >= 24 && receivedAgo.asHours() < 48)
						temp.receivedTime = "1 day ago"
					else if (receivedAgo.asHours() >= 48)
						temp.receivedTime = `${Math.floor(receivedAgo.asDays())} days ago`
					else if (receivedAgo.asHours() > 1 && receivedAgo.asHours() < 2)
						temp.receivedTime = "1 hour ago"
					else
						temp.receivedTime = `${Math.floor(receivedAgo.asHours())} hours ago`

					temp.image = convertToImage(temp.provider)
					return temp
				})
			)
		}
		setShow(!show)
	}

	return (
		<div className="fixed z-10 mx-auto min-w-full border-b-2 bg-slate-100 px-2 dark:border-neutral-800 dark:bg-neutral-900 sm:px-6 lg:px-8">
			<div className="flex h-14 items-center justify-between">
				<div className="flex flex-row">
					<IconButton onClick={handleMenuToggle}>
						<MenuIcon className="h-12 dark:text-white" />
					</IconButton>
					<div className="flex items-center justify-center">
						<img
							src={TVSDLOGO}
							alt="TVSD"
							className="ml-10 h-8 cursor-pointer"
							onClick={() => navigate("/live")}
						/>
					</div>
				</div>
				<div className="ml-3 inline-flex h-full flex-row items-center justify-between">
					<IconButton onClick={() => dispatch(toggleTheme())}>
						{theme === "dark" ? (
							<Tooltip title="Toggle Light Mode">
								<LightModeIcon className="dark:text-white" />
							</Tooltip>
						) : (
							<Tooltip title="Toggle Dark Mode">
								<DarkModeIcon className="text-black" />
							</Tooltip>
						)}
					</IconButton>
					<div className="mt-1.5 ml-2" onClick={handleNotificationClick}>
						<Notifications
							data={notifications}
							header={{
								title: "Notifications",
								option: {
									text: "Clear All Notifications",
									onClick: handleClear,
								},
							}}
							icon={
								theme === "dark" ? NotificationIconDark : NotificationIconLight
							}
						/>
						{notificationCounts !== 0 ? (
							<div className="absolute top-[11px] right-[32px] flex h-4 w-4 items-center justify-center rounded-[50%] bg-tvsd-red text-sm font-bold text-white">
								{notificationCounts}
							</div>
						) : (
							""
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

export default Navbar
