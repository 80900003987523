import { useSelector } from "react-redux"
import {
	ComposedChart as Chart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	ResponsiveContainer,
	Cell,
} from "recharts"
import { selectCurrentTheme } from "../../../../redux/menuSlice"

interface DataI {
	name: string
	value: number
}

const COLORS = [
	"#0da356",
	"#068691",
	"#003e8f",
	"#633e98",
	"#9d3894",
	"#cb3586",
	"#ed406f",
	"#ff5b53",
	"#ff8033",
	"#ffa600",
]

const ComposedChart = ({ data }: { data: DataI[] }) => {
	const theme = useSelector(selectCurrentTheme)

	function CustomizedTick(prop: any) {
		const { x, y, stroke, payload } = prop
		return (
			<g transform={`translate(${x - 90},${y - 10})`}>
				<text
					x={0}
					y={0}
					dy={50}
					fill={theme === "dark" ? "white" : "black"}
					transform="rotate(270)"
					fontSize={"12px"}
				>
					{payload.value
						.split("-")
						.map((el: any) => el.toUpperCase())
						.join(" ")}
				</text>
			</g>
		)
	}

	function CustomizedLabel(prop: any) {
		const { x, y, value } = prop
		return (
			<text
				x={x}
				y={y}
				dy={-4}
				dx={24}
				fill={theme === "dark" ? "white" : "black"}
				fontSize={14}
				textAnchor="middle"
			>
				{value}
			</text>
		)
	}

	return (
		<ResponsiveContainer
			width={"99%"}
			aspect={3}
			className="flex items-center justify-center"
		>
			<Chart
				data={data}
				margin={{
					top: 20,
					right: 20,
					bottom: 50,
					left: 20,
				}}
			>
				<CartesianGrid stroke={theme === "dark" ? "white" : "black"} />
				<XAxis
					dataKey="name"
					interval={0}
					tick={CustomizedTick}
					stroke={theme === "dark" ? "white" : "black"}
				/>
				<YAxis stroke={theme === "dark" ? "white" : "black"} />
				<Tooltip wrapperClassName="dark:!bg-neutral-800 dark:!text-white" />
				<Bar
					dataKey="value"
					barSize={50}
					fill="#8884d8"
					isAnimationActive={false}
					label={CustomizedLabel}
				>
					{data.map((entry, index) => (
						<Cell
							key={`cell-${index}`}
							fill={
								index > COLORS.length - 1
									? COLORS[index % COLORS.length]
									: COLORS[index]
							}
						/>
					))}
				</Bar>
			</Chart>
		</ResponsiveContainer>
	)
}

export default ComposedChart
