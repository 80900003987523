import axios from 'axios';
import { useDispatch } from 'react-redux';
import { authenticate } from '../redux/authSlice';

const useRefreshToken = () => {
    const dispatch = useDispatch()

    const refresh = async () => {
        axios.defaults.withCredentials = true;
        axios.defaults.baseURL = process.env.REACT_APP_API_URL
        const response = await axios.get(`/auth/refresh`);
        dispatch(authenticate(response.data))

        return response.data.token;
    }

    return refresh;
};

export default useRefreshToken;