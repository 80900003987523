import { createContext } from "react";

export enum AlertCrudEnum {
  Idle,
  Create,
  Edit,
  Delete,
}

export interface AlertsI {
  ID: string;
  Condition: string;
  Provider: string;
  Repeat: boolean;
  "Send Email": boolean;
  Value: number;
  "Send Notification": boolean;
}

export interface AlertContextI {
  openModal: boolean;
  setOpenModal: (openModal: boolean) => void;
  submitSuccess: boolean;
  setSubmitSuccess: (submitSuccess: boolean) => void;
  alertCrud: AlertCrudEnum;
  setAlertCrud: (alertCrud: AlertCrudEnum) => void;
  alertData: AlertsI[];
  setAlertData: (alertData: any[]) => void;
  selectedRow: AlertsI[];
  setSelectedRow: (selectedRow: AlertsI[]) => void;
}

export const DefaultAlertContextValue: AlertContextI = {
  openModal: false,
  setOpenModal: () => null,
  submitSuccess: false,
  setSubmitSuccess: () => null,
  alertCrud: AlertCrudEnum.Idle,
  setAlertCrud: () => null,
  alertData: [],
  setAlertData: () => null,
  selectedRow: [
    {
      ID: "",
      Condition: "",
      Provider: "Twilio",
      Repeat: false,
      "Send Email": true,
      "Send Notification": true,
      Value: -1,
    },
  ],
  setSelectedRow: () => null,
};

export const AlertContext = createContext<AlertContextI>(
  DefaultAlertContextValue
);
