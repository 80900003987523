import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { selectCurrentToken } from "../../redux/authSlice";

interface IRoute {
  redirectPath?: string;
  children?: any;
}

const ProtectedRoute = ({ redirectPath = "/login", children }: IRoute) => {
  const token = useSelector(selectCurrentToken);
  const [render, setRender] = useState(<></>);
  const location = useLocation();

  useEffect(() => {
    if (!token)
      setRender(
        <Navigate to={redirectPath} state={{ from: location }} replace />
      );
    else setRender(children ? children : <Outlet />);
  }, [token]);

  return render;
};

export default ProtectedRoute;
