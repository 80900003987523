import { useDispatch } from "react-redux"
import { loading, notLoading } from "../../redux/authSlice"
import { useNavigate } from "react-router-dom"
import { useForgotPasswordMutation } from "../../redux/authApiSlice"
import CircularProgress from "@mui/material/CircularProgress/CircularProgress"
import { Form, Formik } from "formik"
import * as Yup from "yup"
import TVSDLOGO from "../../assets/TVSDLOGO.svg"
import { useAlert } from "react-alert"

const ForgotPassword = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const alert = useAlert()
	const [forgotPassword] = useForgotPasswordMutation()

	const content = (
		<div className="flex min-h-screen flex-col justify-center">
			<div className="mx-auto w-full max-w-xl">
				<div className="mb-3 flex h-28 items-center justify-center">
					<img src={TVSDLOGO} alt="tvsdlogo" className="h-14" />
				</div>

				<div className="h-auto rounded-3xl border bg-white shadow-lg dark:border-neutral-700 dark:bg-neutral-900  dark:shadow-black sm:h-80">
					<Formik
						initialValues={{ email: "" }}
						onSubmit={async (values, actions) => {
							dispatch(loading())
							let resp = await forgotPassword({
								email: values.email,
							})
							dispatch(notLoading())
							if (resp?.error)
								actions.setStatus({
									error: resp?.error?.data?.message,
								})
							else if (resp?.data?.message === "Email sent!") {
								alert.show(resp.data.message, {
									type: "success",
								})
								navigate("/login")
							} else
								actions.setStatus({
									error: resp?.error?.data?.message,
								})
							actions.setSubmitting(false)
						}}
						validateOnChange={false}
						validationSchema={Yup.object().shape({
							email: Yup.string()
								.email("Invalid email")
								.matches(
									/^[A-Za-z0-9._%+-]+@(tvsd\.ai|tvsmotor.com)$/,
									"Invalid email domain"
								)
								.required("Email is a required field"),
						})}
					>
						{(props) => {
							const { errors, handleChange, isSubmitting, status } = props
							return (
								<Form className="flex flex-col p-10">
									<h1 className="mb-4 text-3xl">Forgot Password</h1>
									<p className="text-mg mb-5">
										Please enter your registered email address. We'll send the
										instructions to help reset your password.
									</p>
									<div className="mb-5">
										<input
											type="text"
											placeholder="Email"
											name="email"
											onChange={handleChange}
											className="h-12 w-full rounded-md border border-gray-400 bg-[length:30px] bg-no-repeat pl-4 focus:outline-blue-900 dark:border-neutral-700 dark:bg-neutral-800 placeholder:dark:text-white sm:bg-mail sm:pl-14"
											style={{ backgroundPosition: "1rem center" }}
										/>
										{errors.email ? (
											<div className="mt-1 text-red-600">{errors.email}</div>
										) : null}
									</div>
									<div className="flex items-center justify-center">
										{!isSubmitting ? (
											<button
												type="submit"
												className="h-10 w-2/5 rounded-lg bg-tvsd-blue text-white"
												disabled={isSubmitting}
											>
												Submit
											</button>
										) : (
											<CircularProgress />
										)}
									</div>
									{status ? (
										<div className="mt-3 text-center text-red-600">
											{status.error}
										</div>
									) : null}
								</Form>
							)
						}}
					</Formik>
				</div>
				<div className="mt-6 h-14 text-center ">
					<p>
						Already have an account?{" "}
						<a
							onClick={() => navigate("/login")}
							className="cursor-pointer text-blue-700"
						>
							Sign In
						</a>
					</p>
				</div>
			</div>
		</div>
	)
	return content
}

export default ForgotPassword
