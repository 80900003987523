import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { FetchArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { authenticate, logout } from './authSlice'

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    credentials: 'include',
    prepareHeaders: (headers: Headers, { getState }: any) => {
        headers.set("x-api-key", "w1b5X3ZZiA6BKtuPCzlVI6uHTbZx061qaqKOjslV")
        const token = getState().auth.token
        if (token) {
            headers.set("authorization", `Bearer ${token}`)
        }
        return headers
    }
})

const baseQueryWithReauth = async (args: string | FetchArgs, api: BaseQueryApi, extraOptions: {}) => {
    let result = await baseQuery(args, api, extraOptions)

    if (result?.error?.status === 401 || result?.error?.status === 400) {
        // send refresh token to get new access token
        const refreshResult = await baseQuery('/auth/refresh', api, extraOptions)
        if (refreshResult?.data) {
            const state: any = api.getState()
            // store the new token
            api.dispatch(authenticate({ ...refreshResult.data as {} }))
            // retry the original query with new access token
            result = await baseQuery(args, api, extraOptions)
        } else {
            api.dispatch(logout())
        }
    }
    return result
}

export const apiSlice: any = createApi({
    baseQuery: baseQueryWithReauth,
    endpoints: builder => ({})
})