import { useEffect, useState } from "react"
import DatePicker from "react-datepicker"
import moment from "moment"
import "react-datepicker/dist/react-datepicker.css"
import useAxios from "../../../hooks/useAxios"
import Navbar from "../../../components/navbar/Navbar"
import Sidebar from "../../../components/sidebar/Sidebar"
import Datatable from "../../../components/datatable/Datatable"
import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef"
import { GridSortModel } from "@mui/x-data-grid/models/gridSortModel"
import Select, { components, CSSObjectWithLabel } from "react-select"
import PieChart from "../../../components/chart/pie/PieChart"
import ComposedChart from "../../../components/chart/line/composed/ComposedChart"
import CircularProgress from "@mui/material/CircularProgress/CircularProgress"
import { selectCurrentMenuState } from "../../../redux/menuSlice"
import { useSelector } from "react-redux"
import BarChartIcon from "@mui/icons-material/BarChart"
import PieChartIcon from "@mui/icons-material/PieChart"

interface DailyInfoI {
	[key: string]: TableI
}

interface TableI {
	Headers: []
	Rows: RowI[]
}

interface RowI {
	[key: string]: string | number
}

const DailyInfo = () => {
	const showMenu = useSelector(selectCurrentMenuState)
	const [date, setDate] = useState(
		moment().hours() > 9
			? moment().toDate()
			: moment().subtract(1, "day").toDate()
	)
	const [data, setData] = useState<DailyInfoI[]>([])
	const [selectOption, setSelectOption] = useState<string[]>([])
	const [isLoading, setIsLoading] = useState(true)
	const fetch = useAxios()
	const { Option } = components

	const options = [
		{
			value: "Bar Chart",
			label: "Bar Chart",
		},
		{
			value: "Pie Chart",
			label: "Pie Chart",
		},
	]

	const DatePickerInput = ({ onClick }: any) => (
		<button
			className="h-14 w-28 rounded-md bg-tvsd-blue p-2 text-white"
			onClick={onClick}
		>
			{"Change Date"}
		</button>
	)

	const IconOption = (props: any) => (
		<Option {...props}>
			<div className="inline-flex items-center p-2">
				{props.data.value === "Bar Chart" ? (
					<BarChartIcon className="mr-2 w-9" fontSize="large" />
				) : (
					<PieChartIcon className="mr-2 w-9" fontSize="large" />
				)}
				{props.data.label}
			</div>
		</Option>
	)

	const IconValueContainer = (props: any) => (
		<components.ValueContainer {...props}>
			<div className="inline-flex items-center p-2">
				{props.selectProps.value.value === "Bar Chart" ? (
					<BarChartIcon className="mr-2 w-9" fontSize="large" />
				) : (
					<PieChartIcon className="mr-2 w-9" fontSize="large" />
				)}
				{props.children}
			</div>
		</components.ValueContainer>
	)

	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true)
			const resp = await fetch.post("/report/dailyinfo", {
				date: moment(date).format("D-M-YYYY"),
			})

			const respData: DailyInfoI[] = resp.data

			setData(respData)
			setSelectOption(new Array(resp.data.length - 2).fill("Bar Chart"))
		}

		fetchData()
		setIsLoading(false)
	}, [date])

	return (
		<div className="flex h-[1px] min-h-screen w-full flex-col ">
			<Navbar />
			<div className="flex max-h-screen min-w-[900px] max-w-[99.5%] flex-row">
				<Sidebar />
				<div
					className={`mt-14 flex h-live-container w-full flex-col p-5 ${
						!showMenu ? "ml-28 w-container" : ""
					}`}
				>
					<div className="flex flex-row justify-between">
						<div className="flex-[10]">
							<h2 className="p-3 text-4xl font-bold text-tvsd-blue dark:text-tvsd-red">
								{"Daily Information on "}
								<span className="inline-block">
									{moment(date).format("D MMMM YYYY")}
								</span>
							</h2>
						</div>
						<div className="flex w-fit flex-[1] items-center justify-end">
							{isLoading ? (
								<CircularProgress disableShrink size={30} />
							) : (
								<DatePicker
									placeholderText="Change Date"
									selected={date}
									onChange={(date: Date) => setDate(date)}
									minDate={moment({ year: 2022, month: 2, day: 1 }).toDate()}
									maxDate={
										moment().hours() > 9
											? moment().toDate()
											: moment().subtract(1, "day").toDate()
									}
									customInput={<DatePickerInput />}
								/>
							)}
						</div>
					</div>
					<span className="pt-3 pl-3 text-lg">
						Data on the current date will only be available after 09:00 SGT
					</span>
					<span className="pb-3 pl-3 text-lg">
						Data smaller than 1% are omitted from the graphs
					</span>
					{data.length !== 0
						? data.map((type, idx) => {
								/** Cleaning data for table */
								// extract headers
								const Headers: string[] = Object.values(type)[0]["Headers"]
								// extract rows and add unique index
								const Rows: any = Object.values(type)[0]["Rows"].map(
									(v, idx) => ({
										...v,
										id: idx + 1,
									})
								)
								// define a column array and set id as first field
								const columns: GridColDef[] = [
									{
										field: "id",
										headerName: "ID",
									},
								]
								// define default sort model
								const sortModel: GridSortModel = [
									{
										field: Headers[1],
										sort: "desc",
									},
								]
								// create a new column for each header
								Headers.forEach((column) => {
									columns.push({
										field: column,
										headerName: column,
										flex: 2,
										headerClassName: "text-[16px] dark:bg-neutral-900",
									})
								})
								/** Cleaning data for chart */
								let chartData: any[] = []
								if (
									Object.keys(type)[0] !== "Twilio CDI" &&
									Object.keys(type)[0] !== "SMS Service Providers"
								) {
									columns.forEach((column, idx) => {
										// second index (FeederName) is x-axis, third index (Value) is y-axis
										if (idx === 1 || idx === 2) {
											chartData.push(
												Rows.map((row: any) => {
													return row[column.field]
												})
											)
										}
									})
									chartData = chartData[0].map((item: any, idx: number) => ({
										name: item,
										value: chartData[1][idx],
									}))
									// get total sum of all values
									const sum = chartData
										.map((data) => data.value)
										.reduce((a, b) => a + b)
									// remove values less than 1%
									chartData = chartData.filter(
										(data) => (data.value / sum) * 100 >= 1
									)
								}
								return (
									<div className="flex flex-col border-b border-gray-400 p-3 pt-8 pb-16 lg:flex-row">
										<div className="h-full w-full flex-[1]">
											<p className="text-lg font-bold text-tvsd-blue dark:text-tvsd-red">
												{Object.keys(type)[0]}
											</p>
											<div className="flex h-full items-center justify-center">
												<Datatable
													height={"100%"}
													width={"100%"}
													columns={columns}
													rows={Rows}
													sortModel={sortModel}
												/>
											</div>
										</div>
										{chartData.length !== 0 ? (
											<div className="h-full w-full flex-[2]">
												<div className="ml-auto mr-0 w-56 pt-10 lg:pt-0">
													<Select
														value={{
															value: selectOption[idx - 2],
															label: selectOption[idx - 2],
														}}
														onChange={(chart: any) => {
															setSelectOption((prevArr) => {
																let temp = [...prevArr]
																temp[idx - 2] = chart?.value || "Bar Chart"
																return temp
															})
														}}
														options={options}
														components={{
															Option: IconOption,
															ValueContainer: IconValueContainer,
														}}
														className="select-container w-56"
														classNamePrefix="select"
													/>
												</div>
												<div className="h-full">
													{selectOption[idx - 2] === "Bar Chart" ? (
														<ComposedChart data={chartData} />
													) : (
														<PieChart data={chartData} />
													)}
												</div>
											</div>
										) : null}
									</div>
								)
						  })
						: ""}
				</div>
			</div>
		</div>
	)
}

export default DailyInfo
