import {
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	ResponsiveContainer,
	LineChart,
	Line,
	Legend,
} from "recharts"

import { useContext, useEffect, useState } from "react"
import Slider from "@mui/material/Slider"
import Button from "@mui/material/Button"
import { GraphContextI } from "../../../../context/GraphContext"
import { useSelector } from "react-redux"
import { selectCurrentTheme } from "../../../../redux/menuSlice"
import useWindowDimensions from "../../../../hooks/useWindowDimensions"

/**
 * A chart component that displays data passed in through context.
 * @param context: useContext that should conform to GraphContextI as defined in GraphContext.tsx
 * @param title: Title of graph to be displayed
 */
const DynamicChart = ({ context, title }: { context: any; title: string }) => {
	const theme = useSelector(selectCurrentTheme)
	const { width } = useWindowDimensions()
	const { displayData, sliderValue, pause, setSliderValue, setPause } =
		useContext<GraphContextI>(context)
	const [graphData, setGraphData] = useState(displayData)

	const handlePause = () => {
		setPause(!pause)
	}

	const handleSliderChange = (e: any, newValue: any) => {
		setSliderValue(newValue)
		if (newValue === 100) setPause(false)
		else setPause(true)
	}

	useEffect(() => {
		let value = Math.floor((displayData.length * sliderValue) / 100)
		if (displayData.length > 5)
			setGraphData(
				displayData.slice(value > 5 ? value - 5 : 0, value === 0 ? 1 : value)
			)
		else setGraphData(displayData)
	}, [displayData, sliderValue])

	return (
		<div className="flex h-full flex-col">
			<div className="p-2 text-center text-lg font-semibold">{title}</div>
			<ResponsiveContainer
				width="99%"
				aspect={
					width > 1900
						? 3
						: width > 1750
						? 2.8
						: width > 1600
						? 2.7
						: width > 1460
						? 2.5
						: 2.3
				}
			>
				<LineChart
					data={graphData}
					margin={{ top: 10, right: 10, left: 20, bottom: 0 }}
				>
					<XAxis
						dataKey="name"
						stroke={theme === "dark" ? "white" : "black"}
						fontSize={13}
					/>
					<YAxis
						domain={["auto", "auto"]}
						stroke={theme === "dark" ? "white" : "black"}
					/>
					<CartesianGrid strokeDasharray="3 3" className="chartGrid" />
					<Line
						type="monotone"
						dataKey="value"
						name="Balance"
						stroke="#8884d8"
						activeDot={{ r: 8 }}
					/>
					<Tooltip wrapperClassName="dark:!bg-neutral-800 dark:!text-white" />
				</LineChart>
			</ResponsiveContainer>
			<div className="flex h-12 flex-row items-center justify-center p-2">
				<div className="mr-5 flex w-[90%]">
					<Slider
						size="small"
						defaultValue={100}
						aria-label="Default"
						value={sliderValue}
						valueLabelDisplay="auto"
						onChange={handleSliderChange}
					/>
				</div>
				<Button variant="outlined" onClick={handlePause}>
					{!pause ? "Pause" : "Continue"}
				</Button>
			</div>
		</div>
	)
}

export default DynamicChart
