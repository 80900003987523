import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import AlertForm from "../../components/alert/AlertForm"
import CrudTable from "../../components/crudtable/CrudTable"
import Loading from "../../components/loading/Loading"
import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import {
	AlertContext,
	AlertCrudEnum,
	AlertsI,
	DefaultAlertContextValue,
} from "../../context/AlertContext"
import { useAlert } from "react-alert"
import useAlertContextValue from "../../hooks/useAlertContext"
import {
	useDeleteAlertMutation,
	useGetAlertMutation,
} from "../../redux/authApiSlice"
import { selectCurrentUser } from "../../redux/authSlice"
import { selectCurrentMenuState } from "../../redux/menuSlice"

const headerOrder = [
	"ID",
	"Provider",
	"Condition",
	"Value",
	"Send Email",
	"Repeat",
	"Send Notification",
]

const Alerts = () => {
	const showMenu = useSelector(selectCurrentMenuState)
	const { email } = useSelector(selectCurrentUser)
	const [getAlerts] = useGetAlertMutation()
	const [deleteAlert] = useDeleteAlertMutation()
	const [loading, setLoading] = useState(true)
	const [headers, setHeaders] = useState<any[]>()
	const alert = useAlert()
	const alertContextValue = useAlertContextValue()

	const fetchData = async () => {
		setLoading(true)
		const resp = await getAlerts({ email: email })
		if (Object.keys(resp.data).length === 0) {
			setHeaders([])
			alertContextValue.setAlertData([])
			setLoading(false)
			return
		}

		let data = Object.entries(resp.data).map(([key, value]) => {
			if (typeof value === "object") return { ...value, ID: key }
		})

		data = data.map((el: any) => {
			if (el.Condition === "Drop By") return { ...el, Condition: "Drop By (%)" }
			else return el
		})

		data.sort((a: any, b: any) => a.Provider.localeCompare(b.Provider))

		let headers = data.map((alert: any) => {
			return Object.entries(alert).map((param) => {
				const [key, value] = param
				return {
					id: key,
					label: key,
					alignRight: key === "Provider" ? false : true,
					hidden: true,
				}
			})
		})[0]
		headers.sort(
			(a, b) => headerOrder.indexOf(a.id) - headerOrder.indexOf(b.id)
		)
		setHeaders(headers)
		alertContextValue.setAlertData(data)
		setLoading(false)
	}

	useEffect(() => {
		const handleDelete = async () => {
			alertContextValue.setSubmitSuccess(false)
			alertContextValue.setSelectedRow(DefaultAlertContextValue.selectedRow)
			const resp = await deleteAlert({
				email: email,
				provider: alertContextValue.selectedRow.map((row) => row.Provider),
				alertId: alertContextValue.selectedRow.map((row) => row.ID),
			})
			if (
				resp?.error?.status === 401 ||
				resp?.error?.status === 400 ||
				resp?.error?.status === 503
			)
				alert.show(resp?.error?.data?.message, {
					type: "error",
				})
			else {
				alertContextValue.setSubmitSuccess(true)
				alert.show(resp?.data?.message, {
					type: "success",
				})
			}
			return resp
		}

		if (alertContextValue.alertCrud === AlertCrudEnum.Delete) {
			handleDelete()
			alertContextValue.setAlertCrud(AlertCrudEnum.Idle)
		}
	}, [alertContextValue.alertCrud])

	useEffect(() => {
		if (alertContextValue.submitSuccess) fetchData()
	}, [alertContextValue.submitSuccess])

	useEffect(() => {
		fetchData()
	}, [])

	return loading ? (
		<Loading />
	) : (
		<div className="flex h-[1px] min-h-screen w-full flex-col">
			<Navbar />
			<div className="flex max-h-screen flex-row">
				<Sidebar />
				<div
					className={`mt-14 h-live-container w-full p-5 ${
						!showMenu ? "ml-28 w-container" : ""
					}`}
				>
					<h2 className="p-3 text-4xl font-bold text-tvsd-blue dark:text-tvsd-red">
						Customize Your Alerts!
					</h2>
					<span className="p-3 text-lg">
						Create, Edit or Delete your alerts according to your needs. You will
						be notified if your condition is satisfied via email or the in-app
						notification!
					</span>
					<AlertContext.Provider value={alertContextValue}>
						<div className="table-responsive w-100" style={{ padding: "12px" }}>
							<CrudTable
								headers={headers}
								data={alertContextValue.alertData}
								order={headerOrder}
								title={"Alerts"}
								context={AlertContext}
							/>
						</div>
						{alertContextValue.alertCrud === AlertCrudEnum.Create ? (
							<AlertForm context={AlertContext} />
						) : alertContextValue.alertCrud === AlertCrudEnum.Edit ? (
							<AlertForm context={AlertContext} />
						) : null}
					</AlertContext.Provider>
				</div>
			</div>
		</div>
	)
}

export default Alerts
