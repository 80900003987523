import { createSlice } from '@reduxjs/toolkit'

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    name: null,
    email: null,
    token: null,
    notificationCount: 0,
    isAuth: false,
    isFetching: false,
  },
  reducers: {
    authenticate: (state, action) => {
      state.name = action.payload.user?.name;
      state.email = action.payload.user?.email;
      state.token = action.payload.token;
      state.notificationCount = action.payload.user?.notificationCount;
      state.isAuth = true;
      state.isFetching = false;
    },
    logout: (state) => {
      state.name = null;
      state.token = null;
      state.notificationCount = 0;
      state.isAuth = false;
      state.isFetching = false;
    },
    register: (state, action) => {
      state.email = action.payload.email;
    },
    loading: (state) => {
      state.isFetching = true;
    },
    notLoading: (state) => {
      state.isFetching = false;
    },
    seenNotification: (state) => {
      state.notificationCount = 0;
    }
  }
})

export const { authenticate, logout, register, loading, notLoading, seenNotification } = authSlice.actions
export default authSlice.reducer
export const selectCurrentUser = (state: any) => {
  return {
    name: state.auth?.name,
    email: state.auth?.email
  }
}
export const selectCurrentToken = (state: any) => state.auth.token
export const selectLoading = (state: any) => state.auth.isFetching
export const selectNotificationCount = (state: any) => state.auth.notificationCount