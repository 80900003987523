import { DataGrid, GridColDef, GridSortModel } from "@mui/x-data-grid"
import { useState } from "react"

const Datatable = ({
	rows,
	columns,
	height,
	width,
	sortModel,
}: {
	rows: any
	columns: GridColDef[]
	height: number | string
	width: number | string
	sortModel?: any
}) => {
	const [sortModelState, setSortModelState] = useState<GridSortModel>(sortModel)
	return (
		<DataGrid
			columns={columns}
			rows={rows}
			pageSize={10}
			rowsPerPageOptions={[10]}
			sortModel={sortModelState}
			onSortModelChange={(model) => setSortModelState(model)}
			sortingOrder={["asc", "desc"]}
			className="overflow-y-auto shadow-md dark:border-neutral-700 dark:bg-neutral-800 dark:text-white dark:shadow-black"
			rowHeight={52}
			autoHeight={true}
			initialState={{
				columns: {
					columnVisibilityModel: {
						id: false,
					},
				},
			}}
		/>
	)
}

export default Datatable
