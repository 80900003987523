import { useEffect } from "react"
import { useSelector } from "react-redux"
import { selectCurrentToken } from "../redux/authSlice"
import useRefreshToken from "./useRefreshToken"
import axiosInstance from '../utils/axiosInstance'

const useAxios = () => {
    const refresh = useRefreshToken()
    const token = useSelector(selectCurrentToken)

    useEffect(() => {
        axiosInstance.interceptors.request.use(
            config => {
                if (config.headers && !config.headers.authorization) {
                    config.headers.authorization = `Bearer ${token}`
                }
                return config;
            }, (error) => Promise.reject(error)
        )
    }, [token])

    axiosInstance.interceptors.response.use(
        response => response,
        async (error) => {
            console.log(error)
            const prevRequest = error?.config
            if ((error?.response?.status === 401 || error?.response?.status === 400) && prevRequest?.sent !== true) {
                prevRequest.sent = true
                const newAccessToken = await refresh()
                prevRequest.headers.authorization = `Bearer ${newAccessToken}`
                return axiosInstance(prevRequest)
            }
            return Promise.reject(error)
        }
    )

    return axiosInstance
}

export default useAxios