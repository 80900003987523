import { Modal } from "react-responsive-modal"
import { Formik, Form } from "formik"
import Select from "react-select"
import * as Yup from "yup"
import "react-responsive-modal/styles.css"
import { useCreateAlertMutation } from "../../redux/authApiSlice"
import { useSelector } from "react-redux"
import { selectCurrentUser } from "../../redux/authSlice"
import { useContext, useEffect } from "react"
import {
	AlertContextI,
	AlertCrudEnum,
	DefaultAlertContextValue,
} from "../../context/AlertContext"
import { useAlert } from "react-alert"
import _ from "lodash"
import { selectCurrentTheme } from "../../redux/menuSlice"
import { CircularProgress } from "@mui/material"

const providerOptions = [
	{ value: "Twilio", label: "Twilio" },
	{ value: "Semaphore", label: "Semaphore" },
	{ value: "MiM SMS", label: "MiM SMS" },
	{ value: "Aakash SMS", label: "Aakash SMS" },
]

const conditionOptions = [
	{ value: "Drop By (%)", label: "Drop By (%)" },
	{ value: "Less Than", label: "Less Than" },
]

const AlertForm = ({ context }: { context: any }) => {
	const { email } = useSelector(selectCurrentUser)
	const [createAlert] = useCreateAlertMutation()
	const alert = useAlert()
	const {
		openModal,
		selectedRow,
		setSelectedRow,
		alertCrud,
		setOpenModal,
		setSubmitSuccess,
	} = useContext<AlertContextI>(context)

	useEffect(() => {
		setSubmitSuccess(false)
	}, [])

	return (
		<div className="w-full">
			<Modal
				open={openModal}
				onClose={() => setOpenModal(false)}
				center
				classNames={{
					modal: "!max-w-[700px] !max-h-[500px] dark:!bg-neutral-800",
				}}
			>
				<h2 className="mb-3 text-2xl font-extrabold text-tvsd-blue">
					Create Your Alert
				</h2>
				<Formik
					initialValues={{
						Provider: selectedRow[0].Provider,
						Condition: selectedRow[0].Condition,
						Value: selectedRow[0].Value,
						"Send Email": selectedRow[0]["Send Email"],
						Repeat: selectedRow[0].Repeat,
						"Send Notification": selectedRow[0]["Send Notification"],
					}}
					onSubmit={async (values, actions) => {
						if (
							_.isEqual({ ...values, ID: selectedRow[0].ID }, selectedRow[0])
						) {
							actions.setStatus({
								success: "No changes are made. Please try again.",
							})
							actions.setSubmitting(false)
							return
						}

						let newAlert
						alertCrud === AlertCrudEnum.Edit
							? (newAlert = { ...values, Email: email, ID: selectedRow[0].ID })
							: (newAlert = {
									...values,
									Email: email,
									Condition: values.Condition.split(" (%)")[0],
							  })
						const resp = await createAlert(newAlert)
						if (
							resp?.error?.status === 401 ||
							resp?.error?.status === 400 ||
							resp?.error?.status === 503
						)
							actions.setStatus({
								success: resp.error.data.message,
							})
						else {
							alert.show(resp?.data?.message, {
								type: "success",
							})
							setSelectedRow(DefaultAlertContextValue.selectedRow)
							setSubmitSuccess(true)
							setOpenModal(false)
						}
						actions.setSubmitting(false)
					}}
					validateOnChange={false}
					validationSchema={Yup.object().shape({
						Provider: Yup.string()
							.required()
							.oneOf(providerOptions.map((el) => el.label)),
						Condition: Yup.string()
							.required()
							.oneOf(conditionOptions.map((el) => el.label)),
						Value: Yup.number().required().positive(),
						"Send Email": Yup.boolean().required(),
						Repeat: Yup.boolean().required(),
						"Send Notification": Yup.boolean().required(),
					})}
				>
					{(props) => {
						const {
							values,
							errors,
							handleChange,
							setFieldValue,
							isSubmitting,
							status,
						} = props
						return (
							<Form>
								<div className="flex flex-col">
									<label htmlFor="provider" className="text-lg font-semibold">
										Provider
									</label>
									<Select
										className="select-alert-container"
										classNamePrefix="select-alert"
										options={providerOptions}
										value={{ value: values.Provider, label: values.Provider }}
										onChange={(provider) =>
											setFieldValue("Provider" || "", provider?.value || "")
										}
										id="provider"
									/>
									{errors.Provider ? (
										<div className="error">{errors.Provider}</div>
									) : null}
									<div className="my-5 inline-flex w-full">
										<div className="flex flex-1 flex-col justify-start pr-5">
											<label
												htmlFor="condition"
												className="text-lg font-semibold"
											>
												Condition
											</label>
											<Select
												className="select-alert-container"
												classNamePrefix="select-alert"
												options={conditionOptions}
												value={{
													value: values.Condition,
													label: values.Condition,
												}}
												onChange={(condition) =>
													setFieldValue(
														"Condition" || "",
														condition?.value || ""
													)
												}
												id="condition"
											/>
											{errors.Condition ? (
												<div className="error">{errors.Condition}</div>
											) : null}
										</div>
										<div className="flex flex-1 flex-col justify-start">
											<label htmlFor="Value" className="text-lg font-semibold">
												Value
											</label>
											<input
												id="Value"
												onChange={handleChange}
												defaultValue={values.Value === -1 ? "" : values.Value}
												className="h-[38px] w-full rounded-md border border-gray-400 pl-3 dark:bg-inherit"
											/>
											{errors.Value ? (
												<div className="error">{errors.Value}</div>
											) : null}
										</div>
									</div>
									<div className="flex-col pb-5">
										<div className="flex justify-between pb-3">
											<label
												htmlFor="Send Email"
												className="text-lg font-semibold"
											>
												Receive Via Email
											</label>
											<label className="relative inline-flex cursor-pointer items-center">
												<input
													id="Send Email"
													type="checkbox"
													defaultChecked={values["Send Email"]}
													onChange={handleChange}
													className="peer sr-only"
												/>
												<div className="peer h-5 w-12 rounded-full bg-gray-400 after:absolute after:top-[3px] after:left-[0.5px] after:h-6 after:w-6 after:rounded-full after:border after:border-gray-300 after:bg-black after:transition-all after:content-[''] peer-checked:bg-tvsd-blue peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 dark:border-gray-600 dark:bg-gray-700 dark:after:bg-white dark:peer-focus:ring-blue-800"></div>
											</label>
										</div>
										<div className="flex justify-between pb-3">
											<label htmlFor="Repeat" className="text-lg font-semibold">
												Repeat Email Alert
											</label>
											<label className="relative inline-flex cursor-pointer items-center">
												<input
													id="Repeat"
													type="checkbox"
													defaultChecked={values.Repeat}
													onChange={handleChange}
													className="peer sr-only"
												/>
												<div className="peer h-5 w-12 rounded-full bg-gray-400 after:absolute after:top-[3px] after:left-[0.5px] after:h-6 after:w-6 after:rounded-full after:border after:border-gray-300 after:bg-black after:transition-all after:content-[''] peer-checked:bg-tvsd-blue peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 dark:border-gray-600 dark:bg-gray-700 dark:after:bg-white dark:peer-focus:ring-blue-800"></div>
											</label>
										</div>
										<div className="flex justify-between pb-3">
											<label
												htmlFor="Send Notification"
												className="text-lg font-semibold"
											>
												Receive Via Notification
											</label>
											<label className="relative inline-flex cursor-pointer items-center">
												<input
													id="Send Notification"
													type="checkbox"
													defaultChecked={values["Send Notification"]}
													onChange={handleChange}
													className="peer sr-only"
												/>
												<div className="peer h-5 w-12 rounded-full bg-gray-400 after:absolute after:top-[3px] after:left-[0.5px] after:h-6 after:w-6 after:rounded-full after:border after:border-gray-300 after:bg-black after:transition-all after:content-[''] peer-checked:bg-tvsd-blue peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 dark:border-gray-600 dark:bg-gray-700 dark:after:bg-white dark:peer-focus:ring-blue-800"></div>
											</label>
										</div>
									</div>
									<div className="flex flex-col items-center justify-center">
										{isSubmitting ? (
											<CircularProgress />
										) : (
											<button
												type="submit"
												disabled={isSubmitting}
												className="h-10 w-1/2 rounded-lg bg-tvsd-blue text-white"
											>
												Submit
											</button>
										)}
										{status ? (
											<div className="error">{status.success}</div>
										) : null}
									</div>
								</div>
							</Form>
						)
					}}
				</Formik>
			</Modal>
		</div>
	)
}

export default AlertForm
