import Datatable from "../../components/datatable/Datatable"
import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import { GridColDef, GridComparatorFn } from "@mui/x-data-grid"
import { useEffect, useState } from "react"
import DownloadIcon from "@mui/icons-material/Download"
import { IconButton } from "@mui/material"
import Loading from "../../components/loading/Loading"
import useAxios from "../../hooks/useAxios"
import moment from "moment"
import { useSelector } from "react-redux"
import { selectCurrentMenuState } from "../../redux/menuSlice"

const Report = () => {
	const showMenu = useSelector(selectCurrentMenuState)
	const [rows, setRows] = useState([])
	const [loading, setLoading] = useState(true)
	const fetch = useAxios()

	const dateComparator: GridComparatorFn<string> = (pdf1, pdf2) => {
		return moment(
			pdf1.split("TVSD Operation Report")[1].split(".pdf")[0],
			"DD-MM-YYYY"
		).diff(
			moment(
				pdf2.split("TVSD Operation Report")[1].split(".pdf")[0],
				"DD-MM-YYYY"
			)
		)
	}

	const columns: GridColDef[] = [
		{ field: "id", headerName: "ID", headerClassName: "bg-gray-300 font-bold" },
		{
			field: "name",
			headerName: "Report Name (Last 30 Days)",
			flex: 4,
			headerClassName: "dark:bg-neutral-900 bg-gray-300 font-bold text-[16px]",
			sortComparator: dateComparator,
		},
	]

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true)
			let resp = await fetch.get("/report/pdfreport")

			resp.data.map((row: any, id: number) => {
				let temp = (row["id"] = id + 1)
				return temp
			})
			setRows(resp.data)
			setLoading(false)
		}

		fetchData()
	}, [])

	const downloadColumn: GridColDef[] = [
		{
			field: "link",
			headerName: "Download",
			sortable: false,
			hideable: false,
			filterable: false,
			headerClassName: "dark:bg-neutral-900 bg-gray-300 text-[16px]",
			headerAlign: "center",
			align: "center",
			renderCell: (params: any) => {
				return (
					<div className="cellAction">
						<IconButton href={params.row.link} target="_blank">
							<DownloadIcon />
						</IconButton>
					</div>
				)
			},
		},
	]

	return loading ? (
		<Loading />
	) : (
		<div className="flex h-[1px] min-h-screen w-full flex-col ">
			<Navbar />
			<div className="flex max-h-screen flex-row">
				<Sidebar />
				<div
					className={`mt-14 flex h-live-container w-full items-center justify-center p-5 ${
						!showMenu ? "ml-28 w-container" : ""
					}`}
				>
					<div className="h-full w-full max-w-[1000px]">
						<p className="text-4xl font-bold text-tvsd-blue dark:text-tvsd-red">
							Daily Operation Report
						</p>
						<div className="mt-5 min-w-[500px]">
							<Datatable
								height="650px"
								width="100%"
								columns={columns.concat(downloadColumn)}
								rows={rows}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Report
