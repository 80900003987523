import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  authenticate,
  selectCurrentToken,
  selectCurrentUser,
} from "../../redux/authSlice";
import { useRefreshMutation } from "../../redux/authApiSlice";
import Loading from "../loading/Loading";

const PersistLogin = () => {
  const [refresh, { isLoading }] = useRefreshMutation();
  const dispatch = useDispatch();
  const token = useSelector(selectCurrentToken);

  // Refresh token if token doesn't exist
  useEffect(() => {
    const verifyRefreshToken = async () => {
      try {
        let resp = await refresh();
        if (!resp.error) dispatch(authenticate({ ...(resp.data as {}) }));
      } catch (err) {
        console.error(err);
      }
    };

    if (!token) verifyRefreshToken();
  }, []);

  return <>{isLoading ? <Loading /> : <Outlet />}</>;
};

export default PersistLogin;
