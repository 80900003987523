import moment from "moment"
import { useState } from "react"
import { useSelector } from "react-redux"
import {
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	ResponsiveContainer,
	LineChart,
	Line,
	Legend,
} from "recharts"
import { selectCurrentTheme } from "../../../../redux/menuSlice"

/**
 * A chart component that displays data passed in through prop.
 * @param data: Data to be displayed on graph
 * @param xlabel: Label of x-axis, default is "name"
 * @param ylabel: Label of y-axis, default is "value"
 * @param title: Title of graph to be displayed
 */
const StaticChart = ({
	data,
	xlabel = "name",
	title,
}: {
	data: any[]
	xlabel?: string
	title?: string
}) => {
	const theme = useSelector(selectCurrentTheme)
	const color = [
		"#8884d8",
		"#0000ff",
		"#9d02d7",
		"#cd34b5",
		"#ea5f94",
		"#fa8775",
		"#ffb14e",
		"#ffd700",
	]

	const CustomTooltip = ({ active, payload, label }: any) => {
		console.log(payload)
		if (active && payload && payload.length) {
			return (
				<div className="grid grid-cols-2">
					{payload.map((option: any) => {
						const optionKey = option.dataKey.split(" ")[0]
						return (
							<div className="rounded-md border bg-gray-300 shadow-lg dark:bg-neutral-800 dark:shadow-black">
								<div className="flex flex-col items-center justify-center">
									<div className="p-2 text-sm font-bold text-tvsd-blue dark:text-tvsd-red md:text-lg">
										{optionKey}
									</div>
									<div className="flex flex-row">
										{Object.entries(option.payload).map(([key, value]: any) => {
											if (
												key !== "name" &&
												key.split(" ")[0] === optionKey &&
												value !== undefined
											)
												return (
													<div className="flex flex-col items-center justify-center px-2 pb-2">
														<p className="whitespace-nowrap text-xs leading-5 md:text-base">
															{value}
														</p>
														<p className="whitespace-nowrap text-xs font-semibold md:text-base">
															{key.split(optionKey)[1].replace(/SMS/, "")}
														</p>
													</div>
												)
										})}
									</div>
								</div>
							</div>
						)
					})}
				</div>
			)
		}

		return null
	}

	return (
		<div className="flex-[4] p-5">
			<div className="title" color="gray">
				{title}
			</div>
			<ResponsiveContainer width="99%" aspect={3}>
				<LineChart
					width={800}
					height={250}
					data={data}
					margin={{ top: 10, right: 10, left: 20, bottom: 0 }}
				>
					<XAxis
						dataKey={xlabel}
						stroke={theme === "dark" ? "white" : "black"}
						fontSize={13}
					/>
					<YAxis
						domain={["auto", "auto"]}
						stroke={theme === "dark" ? "white" : "black"}
					/>
					<CartesianGrid strokeDasharray="3 3" className="chartGrid" />
					<Legend />
					{data.length !== 0
						? Object.keys(data[0]).map((key, index) => {
								if (
									key !== "name" &&
									key.split("MTD").length === 1 &&
									key.split("Previous Day").length === 1
								)
									return (
										<Line
											type="monotone"
											dataKey={key}
											name={key}
											stroke={
												index > color.length ? "#8884d8" : color[index - 1]
											}
											activeDot={{ r: 8 }}
										/>
									)
								else return
						  })
						: ""}
					<Tooltip
						wrapperClassName="dark:!bg-neutral-800 dark:!text-white"
						content={<CustomTooltip />}
					/>
				</LineChart>
			</ResponsiveContainer>
		</div>
	)
}

export default StaticChart
