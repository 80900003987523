import "./index.css"
import ReactDOM from "react-dom/client"
import App from "./app/App"
import { Provider } from "react-redux"
import store from "./redux/store"
import { positions, Provider as AlertProvider } from "react-alert"
import AlertTemplate from "react-alert-template-basic"

const alertOptions = {
	timeout: 5000,
	position: positions.BOTTOM_CENTER,
	offset: "20px",
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
	<Provider store={store}>
		<AlertProvider template={AlertTemplate} {...alertOptions}>
			<App />
		</AlertProvider>
	</Provider>
)
