import { useMemo, useState } from "react";
import { AlertContextI, AlertCrudEnum, DefaultAlertContextValue } from "../context/AlertContext";

/**
 * A custom hook that fetches from AlertContext
 */
function useAlertContextValue(): AlertContextI {
    const [openModal, setOpenModal] = useState(false)
    const [submitSuccess, setSubmitSuccess] = useState(false)
    const [alertCrud, setAlertCrud] = useState<AlertCrudEnum>(AlertCrudEnum.Create)
    const [alertData, setAlertData] = useState<any>()
    const [selectedRow, setSelectedRow] = useState<any>(DefaultAlertContextValue.selectedRow)

    return useMemo(() => ({
        openModal,
        setOpenModal,
        submitSuccess,
        setSubmitSuccess,
        alertCrud,
        setAlertCrud,
        alertData,
        setAlertData,
        selectedRow,
        setSelectedRow
    }), [
        openModal,
        setOpenModal,
        submitSuccess,
        setSubmitSuccess,
        alertCrud,
        setAlertCrud,
        alertData,
        setAlertData,
        selectedRow,
        setSelectedRow
    ]);
}

export default useAlertContextValue;