import { createSlice } from '@reduxjs/toolkit'

export const menuSlice = createSlice({
    name: 'menu',
    initialState: {
        showMenu: true,
        theme: localStorage.theme
    },
    reducers: {
        toggleMenu: (state) => {
            state.showMenu = !state.showMenu
        },
        toggleTheme: (state) => {
            const root = window.document.documentElement
            root.classList.remove(state.theme)
            state.theme = state.theme === 'dark' ? 'light' : 'dark'
            root.classList.add(state.theme)
            localStorage.setItem('theme', state.theme)
        }
    }
})

const root = window.document.documentElement
root.classList.add(localStorage.theme)

export const { toggleMenu, toggleTheme } = menuSlice.actions
export default menuSlice.reducer
export const selectCurrentMenuState = (state: any) => state.menu.showMenu
export const selectCurrentTheme = (state: any) => state.menu.theme