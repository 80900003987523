import { useDispatch } from "react-redux"
import { register } from "../../redux/authSlice"
import { useNavigate } from "react-router-dom"
import { useRegisterMutation } from "../../redux/authApiSlice"
import { Form, Formik } from "formik"
import CircularProgress from "@mui/material/CircularProgress/CircularProgress"
import * as Yup from "yup"
import YupPassword from "yup-password"
import TVSDLOGO from "../../assets/TVSDLOGO.svg"

const Register = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const [registration] = useRegisterMutation()

	YupPassword(Yup)

	const content = (
		<div className="flex min-h-screen flex-col justify-center">
			<div className="mx-auto w-full max-w-xl">
				<div className="mb-3 flex h-28 items-center justify-center">
					<img src={TVSDLOGO} alt="tvsdlogo" className="h-14" />
				</div>

				<div className="h-auto rounded-3xl border bg-white shadow-lg dark:border-neutral-700 dark:bg-neutral-900 dark:shadow-black sm:h-[50%]">
					<Formik
						initialValues={{
							name: "",
							email: "",
							password: "",
							confirmPassword: "",
						}}
						onSubmit={async (values, actions) => {
							const resp = await registration({
								name: values.name,
								email: values.email,
								password: values.password,
							})
							if (
								resp?.error?.status === 401 ||
								resp?.error?.status === 400 ||
								resp?.error?.status === 503
							)
								actions.setStatus({
									error: resp.error.data.message,
								})
							else {
								dispatch(register({ email: values.email }))
								navigate("/verifyotp")
							}
							actions.setSubmitting(false)
						}}
						validateOnChange={false}
						validationSchema={Yup.object().shape({
							email: Yup.string()
								.email("Invalid email")
								.matches(
									/^[A-Za-z0-9._%+-]+@(tvsd\.ai|tvsmotor.com)$/,
									"Invalid email domain!"
								)
								.required("Email is a required field"),
							name: Yup.string().required("Name is a required field"),
							password: Yup.string()
								.required("Password is a required field")
								.min(8, "Password must be at least 8 characters long")
								.minUppercase(
									1,
									"Password must have at least 1 upper case letter"
								)
								.minLowercase(
									1,
									"Password must have at least 1 lower case letter"
								),
							confirmPassword: Yup.string()
								.required("Confirm password is a required field")
								.oneOf([Yup.ref("password")], "Passwords do not match"),
						})}
					>
						{(props) => {
							const {
								values,
								errors,
								handleChange,
								setFieldValue,
								isSubmitting,
								status,
							} = props
							return (
								<Form className="flex flex-col p-10">
									<h1 className="mb-2 text-3xl">Create your account</h1>
									<p>Password must have at least:</p>
									<ul className="justify-left flex-1 flex-col items-start p-4">
										<li
											className={
												values.password.length >= 8
													? "bg-tick bg-[length:23px] bg-left bg-no-repeat pl-8"
													: "bg-cross bg-[length:23px] bg-left bg-no-repeat pl-8"
											}
										>
											8 Characters
										</li>
										<li
											className={
												values.password !== values.password.toLowerCase()
													? "bg-tick bg-[length:23px] bg-left bg-no-repeat pl-8"
													: "bg-cross bg-[length:23px] bg-left bg-no-repeat pl-8"
											}
										>
											1 Upper case letter
										</li>
										<li
											className={
												values.password !== values.password.toUpperCase()
													? "bg-tick bg-[length:23px] bg-left bg-no-repeat pl-8"
													: "bg-cross bg-[length:23px] bg-left bg-no-repeat pl-8"
											}
										>
											1 Lower case letter
										</li>
									</ul>
									<div className="mb-5">
										<input
											type="text"
											placeholder="Name"
											name="name"
											onChange={handleChange}
											className="h-12 w-full rounded-md border border-gray-400 bg-[length:23px] bg-no-repeat pl-4 focus:outline-blue-900 dark:border-neutral-700 dark:bg-neutral-800 placeholder:dark:text-white sm:bg-profile sm:pl-14"
											style={{ backgroundPosition: "1rem center" }}
										/>
										{errors.name ? (
											<div className="mt-1 text-red-600">{errors.name}</div>
										) : null}
									</div>
									<div className="mb-5">
										<input
											type="text"
											placeholder="Email"
											name="email"
											onChange={handleChange}
											className="h-12 w-full rounded-md border border-gray-400 bg-[length:23px] bg-no-repeat pl-4 focus:outline-blue-900 dark:border-neutral-700 dark:bg-neutral-800 placeholder:dark:text-white sm:bg-mail sm:pl-14"
											style={{ backgroundPosition: "1rem center" }}
										/>
										{errors.email ? (
											<div className="mt-1 text-red-600">{errors.email}</div>
										) : null}
									</div>
									<div className="mb-5">
										<input
											type="password"
											placeholder="Password"
											name="password"
											onChange={handleChange}
											className="h-12 w-full rounded-md border border-gray-400 bg-[length:23px] bg-no-repeat pl-4 focus:outline-blue-900 dark:border-neutral-700 dark:bg-neutral-800 placeholder:dark:text-white sm:bg-key sm:pl-14"
											style={{ backgroundPosition: "1rem center" }}
										/>
										{errors.password ? (
											<div className="mt-1 text-red-600">{errors.password}</div>
										) : null}
									</div>
									<div className="mb-7">
										<input
											type="password"
											placeholder="Confirm Password"
											name="confirmPassword"
											onChange={handleChange}
											className="h-12 w-full rounded-md border border-gray-400 bg-[length:23px] bg-no-repeat pl-4 focus:outline-blue-900 dark:border-neutral-700 dark:bg-neutral-800 placeholder:dark:text-white sm:bg-key sm:pl-14"
											style={{ backgroundPosition: "1rem center" }}
										/>
										{errors.confirmPassword ? (
											<div className="mt-1 text-red-600">
												{errors.confirmPassword}
											</div>
										) : null}
									</div>
									<div className="flex items-center justify-center">
										{!isSubmitting ? (
											<button
												type="submit"
												className="h-10 w-1/2 rounded-lg bg-tvsd-blue text-white"
												disabled={isSubmitting}
											>
												Sign Up
											</button>
										) : (
											<CircularProgress />
										)}
									</div>
									{status ? (
										<div className="mt-3 text-center text-red-600">
											{status.error}
										</div>
									) : null}
								</Form>
							)
						}}
					</Formik>
				</div>
				<div className="mt-6 h-14 text-center ">
					<p>
						Already have an account?{" "}
						<a
							onClick={() => navigate("/login")}
							className="cursor-pointer text-blue-700"
						>
							Sign In
						</a>
					</p>
				</div>
			</div>
		</div>
	)
	return content
}

export default Register
