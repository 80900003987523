import { apiSlice } from "./apiSlice"

export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder: any) => ({
        login: builder.mutation({
            query: (credentials: any) => ({
                url: '/auth/login',
                method: 'POST',
                body: { ...credentials }
            })
        }),
        register: builder.mutation({
            query: (credentials: any) => ({
                url: '/auth/register',
                method: 'POST',
                body: { ...credentials }
            })
        }),
        logout: builder.mutation({
            query: () => ({
                url: '/auth/logout',
                method: 'POST',
            })
        }),
        verify: builder.mutation({
            query: (credentials: any) => ({
                url: '/auth/verify',
                method: 'POST',
                body: { ...credentials }
            })
        }),
        verifyOTP: builder.mutation({
            query: (credentials: any) => ({
                url: '/auth/otp',
                method: 'POST',
                body: { ...credentials }
            })
        }),
        resendOTP: builder.mutation({
            query: (credentials: any) => ({
                url: '/auth/resendotp',
                method: 'POST',
                body: { ...credentials }
            })
        }),
        resetPassword: builder.mutation({
            query: (credentials: any) => ({
                url: '/auth/resetpassword',
                method: 'POST',
                body: { ...credentials }
            })
        }),
        forgotPassword: builder.mutation({
            query: (credentials: any) => ({
                url: '/auth/forgotpassword',
                method: 'POST',
                body: { ...credentials }
            })
        }),
        refresh: builder.mutation({
            query: () => ({
                url: '/auth/refresh',
                method: 'GET',
            })
        }),
        notifications: builder.mutation({
            query: (credentials: any) => ({
                url: '/alert/getnotification',
                method: 'POST',
                body: { ...credentials }
            })
        }),
        deleteNotification: builder.mutation({
            query: (credentials: any) => ({
                url: '/alert/deletenotification',
                method: 'POST',
                body: { ...credentials }
            })
        }),
        createAlert: builder.mutation({
            query: (credentials: any) => ({
                url: '/alert/createalert',
                method: 'POST',
                body: { ...credentials }
            })
        }),
        getAlert: builder.mutation({
            query: (credentials: any) => ({
                url: '/alert/getalert',
                method: 'POST',
                body: { ...credentials }
            })
        }),
        deleteAlert: builder.mutation({
            query: (credentials: any) => ({
                url: '/alert/deletealert',
                method: 'POST',
                body: { ...credentials }
            })
        })
    })
})

export const {
    useLoginMutation, useRegisterMutation, useLogoutMutation, useVerifyMutation, useVerifyOTPMutation, useResendOTPMutation,
    useResetPasswordMutation, useForgotPasswordMutation, useRefreshMutation, useNotificationsMutation, useDeleteNotificationMutation,
    useCreateAlertMutation, useGetAlertMutation, useDeleteAlertMutation
} = authApiSlice