import { createContext } from "react";

/**
 * Graph data interface that should contain a name attribute for X-Axis and value attribute for Y-Axis
 */
export interface Data {
  name: string;
  value: number;
}

/**
 * Graph context interface that defines the context to be stored and shared across different component.
 * @param displayData: data to be displayed on the chart; used as a useEffect dependency that rerenders chart when displayData changes
 * @param setDisplayData: function that sets the state of displayData; a useState function
 * @param pauseData: data that is initially stored when data is fetched. prevents chart from updating display when paused
 * @param setPauseData: function that sets the state of pauseData; a useState function
 * @param sliderValue: useState param that stores the current slider value
 * @param setSliderValue: function that sets the state of sliderValue; a useState function
 * @param pause: pause state
 * @param setPause: function that sets the state of pause; a useState function
 */
export interface GraphContextI {
  displayData: Data[];
  setDisplayData: (data: Data[]) => void;
  pauseData: Data[];
  setPauseData: (data: Data[]) => void;
  sliderValue: number;
  setSliderValue: (value: number) => void;
  pause: boolean;
  setPause: (state: boolean) => void;
  change: number;
  setChange: (change: number) => void;
  lastUpdated: string;
  setLastUpdated: (date: string) => void;
  refresh: boolean;
  setRefresh: (refresh: boolean) => void;
  error: boolean;
  setError: (error: boolean) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  counter: number;
  setCounter: (counter: number) => void;
}

/**
 * The default context value of GraphContext that conforms to the GraphContext interface defined
 */
export const DefaultContextValue: GraphContextI = {
  displayData: [],
  setDisplayData: () => null,
  pauseData: [],
  setPauseData: () => null,
  sliderValue: 100,
  setSliderValue: () => null,
  pause: false,
  setPause: () => null,
  change: 0,
  setChange: () => null,
  lastUpdated: "",
  setLastUpdated: () => null,
  refresh: false,
  setRefresh: () => null,
  error: false,
  setError: () => null,
  loading: false,
  setLoading: () => null,
  counter: 0,
  setCounter: () => null,
};

/**
 * Creates the context using the default context values
 */
export const GraphContext = createContext<GraphContextI>(DefaultContextValue);
