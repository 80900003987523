import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import TVSDLOGO from "../../assets/TVSDLOGO.svg";

const Loading = () => {
  return (
    <div className="min-h-screen">
      <div className="fixed top-1/2 left-1/2 flex h-[200px] w-[400px] -translate-x-1/2 -translate-y-1/2 flex-col items-center justify-center">
        <div className="flex flex-[2] items-center justify-center">
          <img src={TVSDLOGO} className="h-1/2" alt="logo" />
        </div>
        <div className="flex flex-[1] items-center justify-center">
          <CircularProgress />
        </div>
      </div>
    </div>
  );
};

export default Loading;
