import {
	PieChart as Chart,
	Pie,
	Cell,
	ResponsiveContainer,
	Legend,
	Tooltip,
} from "recharts"

const COLORS = [
	"#0da356",
	"#068691",
	"#003e8f",
	"#633e98",
	"#9d3894",
	"#cb3586",
	"#ed406f",
	"#ff5b53",
	"#ff8033",
	"#ffa600",
]

const renderCustomizedLabel = ({ percent }: { percent: number }) => {
	return `${(percent * 100).toFixed(0)}%`
}

interface DataI {
	name: string
	value: number
}

const PieChart = ({ data }: { data: DataI[] }) => {
	return (
		<ResponsiveContainer width={"99%"} aspect={2}>
			<Chart>
				<Pie
					data={data}
					cx="50%"
					cy="50%"
					labelLine={true}
					label={renderCustomizedLabel}
					fill="#8884d8"
					dataKey="value"
					minAngle={1}
					animationDuration={0}
					outerRadius={200}
					legendType={"circle"}
				>
					{data.map((entry, index) => (
						<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
					))}
				</Pie>
				<Legend fontSize={"15px"} />
				<Tooltip
					wrapperClassName="dark:!bg-neutral-800 dark:!text-white"
					itemStyle={{ color: "#8884d8" }}
				/>
			</Chart>
		</ResponsiveContainer>
	)
}

export default PieChart
