import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Login from "../containers/login/Login"
import Register from "../containers/register/Register"
import PersistLogin from "../components/route/PersistLogin"
import ProtectedRoute from "../components/route/ProtectedRoute"
import Live from "../containers/live/Live"
import Report from "../containers/report/Report"
import VerifyOTP from "../containers/otp/VerifyOTP"
import ForgotPassword from "../containers/forgotpassword/ForgotPassword"
import ResetPassword from "../containers/resetpassword/ResetPassword"
import StaticSMS from "../containers/static/sms/StaticSMS"
import DailyInfo from "../containers/static/dailyinfo/DailyInfo"
import Alerts from "../containers/alert/Alerts"

function App() {
	return (
		<Router>
			<Routes>
				<Route path="/">
					<Route path="register" element={<Register />} />
					<Route path="verifyotp" element={<VerifyOTP />} />
					<Route path="forgotpassword">
						<Route path="" element={<ForgotPassword />} />
						<Route path=":email/:token" element={<ResetPassword />} />
					</Route>
				</Route>

				<Route element={<PersistLogin />}>
					<Route element={<ProtectedRoute />}>
						<Route path="/">
							<Route index element={<Live />} />
							<Route path="/report" element={<Report />} />
							<Route path="/static" element={<StaticSMS />} />
							<Route path="/dailyinfo" element={<DailyInfo />} />
							<Route path="/alert" element={<Alerts />} />
							<Route path="/*" element={<Live />} />
						</Route>
					</Route>
				</Route>

				<Route path="/login" element={<Login />} />
			</Routes>
		</Router>
	)
}

export default App
