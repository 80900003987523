import { useMemo, useState } from "react";
import { GraphContextI, Data } from "../context/GraphContext";

/**
 * A custom hook that fetches from GraphContext
 */
function useGraphContextValue(): GraphContextI {
    // Chart context
    const [displayData, setDisplayData] = useState<Data[]>([])
    const [pauseData, setPauseData] = useState<Data[]>([])
    const [sliderValue, setSliderValue] = useState(100)
    const [pause, setPause] = useState(false)
    const [counter, setCounter] = useState(0)

    // Widget context
    const [change, setChange] = useState(0)
    const [lastUpdated, setLastUpdated] = useState("")
    const [refresh, setRefresh] = useState(false)
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)

    return useMemo(() => ({
        displayData,
        setDisplayData,
        pauseData,
        setPauseData,
        sliderValue,
        setSliderValue,
        pause,
        setPause,
        change,
        setChange,
        lastUpdated,
        setLastUpdated,
        refresh,
        setRefresh,
        error,
        setError,
        loading,
        setLoading,
        counter,
        setCounter
    }), [
        displayData,
        setDisplayData,
        pauseData,
        setPauseData,
        sliderValue,
        setSliderValue,
        pause,
        setPause,
        change,
        setChange,
        lastUpdated,
        setLastUpdated,
        refresh,
        setRefresh,
        error,
        setError,
        loading,
        setLoading,
        counter,
        setCounter
    ]);
}

export default useGraphContextValue;