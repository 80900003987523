import Twilio from "../../components/live/SMSProvider/Twilio"
import Aakash from "../../components/live/SMSProvider/Aakash"
import Sidebar from "../../components/sidebar/Sidebar"
import MiM from "../../components/live/SMSProvider/MiM"
import Semaphore from "../../components/live/SMSProvider/Semaphore"
import useGraphContextValue from "../../hooks/useGraphContext"
import { GraphContext } from "../../context/GraphContext"
import Widget from "../../components/widgets/Widget"
import Navbar from "../../components/navbar/Navbar"
import "./live.scss"
import { useSelector } from "react-redux"
import { selectCurrentMenuState } from "../../redux/menuSlice"

const Live = () => {
	const showMenu = useSelector(selectCurrentMenuState)

	const aakashContextValue = useGraphContextValue()
	const twilioContextValue = useGraphContextValue()
	const mimContextValue = useGraphContextValue()
	const semaphoreContextValue = useGraphContextValue()

	return (
		<div className="flex h-[1px] min-h-screen w-full flex-col">
			<Navbar />
			<div className="flex max-h-screen flex-row">
				<Sidebar />
				<div
					className={`mt-10 h-live-container w-full p-5 ${
						!showMenu ? "ml-28 w-container" : ""
					}`}
				>
					<div className="live h-live-container">
						<div className="widget1 rounded-lg border border-gray-300 shadow-md dark:border-neutral-800 dark:shadow-black">
							<GraphContext.Provider value={aakashContextValue}>
								<Widget type="Aakash" context={GraphContext} />
							</GraphContext.Provider>
						</div>
						<div className="widget2 rounded-lg border border-gray-300 shadow-md dark:border-neutral-800 dark:shadow-black">
							<GraphContext.Provider value={twilioContextValue}>
								<Widget type="Twilio" context={GraphContext} />
							</GraphContext.Provider>
						</div>
						<div className="widget3 rounded-lg border border-gray-300 shadow-md dark:border-neutral-800 dark:shadow-black">
							<GraphContext.Provider value={mimContextValue}>
								<Widget type="MiM" context={GraphContext} />
							</GraphContext.Provider>
						</div>
						<div className="widget4 rounded-lg border border-gray-300 shadow-md dark:border-neutral-800 dark:shadow-black">
							<GraphContext.Provider value={semaphoreContextValue}>
								<Widget type="Semaphore" context={GraphContext} />
							</GraphContext.Provider>
						</div>
						<div className="graph1 grid justify-items-center xl:justify-items-end">
							<GraphContext.Provider value={aakashContextValue}>
								<Aakash context={GraphContext} />
							</GraphContext.Provider>
						</div>
						<div className="graph2 grid justify-items-center xl:justify-items-start">
							<GraphContext.Provider value={twilioContextValue}>
								<Twilio context={GraphContext} />
							</GraphContext.Provider>
						</div>
						<div className="graph3 grid justify-items-center xl:justify-items-end">
							<GraphContext.Provider value={mimContextValue}>
								<MiM context={GraphContext} />
							</GraphContext.Provider>
						</div>
						<div className="graph4 grid justify-items-center xl:justify-items-start">
							<GraphContext.Provider value={semaphoreContextValue}>
								<Semaphore context={GraphContext} />
							</GraphContext.Provider>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Live
