import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import RemoveIcon from "@mui/icons-material/Remove"
import RefreshIcon from "@mui/icons-material/Refresh"
import CircularProgress from "@mui/material/CircularProgress"
import { useContext } from "react"
import { IconButton, Tooltip } from "@mui/material"
import { GraphContextI } from "../../context/GraphContext"
import "./widget.scss"
import moment from "moment"

const Widget = ({ type, context }: { type: string; context: any }) => {
	const {
		error,
		loading,
		pauseData,
		lastUpdated,
		change,
		refresh,
		setRefresh,
	} = useContext<GraphContextI>(context)

	const handleRefresh = () => {
		setRefresh(!refresh)
	}

	function generateTitle() {
		let pastRecords = window.sessionStorage.getItem(`${type}` + "Change")

		return (
			<span style={{ whiteSpace: "pre-line" }}>
				<p>{`Past Records\n`}</p>
				<p>
					{!pastRecords
						? "No Records"
						: JSON.parse(pastRecords).map((r: any) => {
								return `${r.date}: ${r.change}\n`
						  })}
				</p>
			</span>
		)
	}

	let currency
	switch (type) {
		case "Aakash":
			currency = "NPR"
			break
		case "MiM":
			currency = "BDT"
			break
		case "Semaphore":
			currency = "PHP"
			break
		case "Twilio":
			currency = "USD"
			break
	}

	return (
		<div className="widget h-full p-4">
			<div className="provider grid place-items-center justify-items-start">
				<span className="text-2xl font-extrabold text-tvsd-blue dark:text-tvsd-red">
					{type}
				</span>
			</div>
			<div className="tracker grid place-items-center justify-items-end">
				<Tooltip title={generateTitle()} className="cursor-help">
					{change > 0 ? (
						<div className="text-xl text-green-500">
							<KeyboardArrowUpIcon />
							{change}
						</div>
					) : change < 0 ? (
						<div className="text-xl text-red-500">
							<KeyboardArrowDownIcon />
							{change}
						</div>
					) : (
						<div className="text-xl">
							<RemoveIcon />
							{0}
						</div>
					)}
				</Tooltip>
			</div>
			<div className="value grid place-items-center justify-items-start">
				<span className="text-3xl font-semibold">
					{pauseData.at(-1)?.value
						? `${pauseData?.at(-1)?.value} ${currency}`
						: "No Data"}
				</span>
			</div>
			<div className="error mr-1 mb-3 grid place-items-center justify-items-end">
				{error ? (
					<span className="error font-extrabold text-red-600">Error</span>
				) : null}
			</div>
			<div className="updated grid place-items-center justify-items-start">
				<span className="text-sm">
					{"Last Updated: "}
					<span className="inline-block">{`${
						lastUpdated
							? moment(lastUpdated, "DD/MM/YYYY h:mm:ss a").format(
									"DD/MM/YYYY h:mm a"
							  )
							: ""
					}`}</span>
				</span>
			</div>
			<div className="refresh grid place-items-center justify-items-end">
				{!loading ? (
					<IconButton onClick={handleRefresh}>
						<RefreshIcon className="dark:text-white" />
					</IconButton>
				) : (
					<CircularProgress disableShrink size={30} />
				)}
			</div>
		</div>
	)
}

export default Widget
