import { useContext } from "react";
import moment from "moment";
import SMSBalance from "../SMSBalance/SMSBalance";
import { GraphContextI } from "../../../context/GraphContext";
import useAxios from "../../../hooks/useAxios";

const type: string = "MiM"; // const name to set in storage

const MiM = ({ context }: { context: any }) => {
  const {
    pauseData,
    counter,
    setPauseData,
    setLastUpdated,
    setChange,
    setError,
    setLoading,
    setCounter,
  } = useContext<GraphContextI>(context);
  const fetch = useAxios();

  const fetchData = async () => {
    setError(false);
    setLoading(true);
    setCounter(counter + 1);
    let date = moment().format("DD/MM/YYYY h:mm:ss a");
    try {
      let resp = await fetch.get("/report/mim");
      if (!resp || !resp.data || !resp.data.balance) return;
      let oldData = pauseData;
      if (oldData.length === 0) {
        let sessionData = window.sessionStorage.getItem("MiM");
        if (sessionData) oldData = JSON.parse(sessionData);
      }

      if (oldData[oldData.length - 1]?.value !== resp.data.balance) {
        // update change in value for widget
        if (oldData[oldData.length - 1]) {
          let newChange = Number(
            (resp.data.balance - oldData[oldData.length - 1].value).toFixed(2)
          );
          setChange(newChange);
          // cache change value for refresh page
          let items = sessionStorage.getItem("MiMChange");
          let newItem;
          if (items) {
            newItem = JSON.parse(items);
            newItem.push({
              date: date,
              change: newChange,
            });
          } else newItem = [{ date: date, change: newChange }];
          window.sessionStorage.setItem("MiMChange", JSON.stringify(newItem));
        }

        // sets the same data for the first data item, otherwise the visibility will be bad with just one dot on the chart
        if (oldData.length === 0) {
          setPauseData([
            ...oldData,
            {
              name: date,
              value: resp.data.balance,
            },
            {
              name: date,
              value: resp.data.balance,
            },
          ]);
        } else {
          setPauseData([
            ...oldData,
            {
              name: date,
              value: resp.data.balance,
            },
          ]);
        }
      } else if (oldData[oldData.length - 1]?.value === resp.data.balance) {
        let items = sessionStorage.getItem("MiM");
        let newItem;
        if (items) {
          newItem = JSON.parse(items);
          newItem.pop();
          newItem.push({
            name: date,
            value: resp.data.balance,
          });
          window.sessionStorage.setItem("MiM", JSON.stringify(newItem));
        }
      }
      setLastUpdated(date);
      setLoading(false);
    } catch (error) {
      setError(true);
      setLoading(false);
    }
  };

  return <SMSBalance type={type} context={context} fetchData={fetchData} />;
};

export default MiM;
